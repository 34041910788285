import React from 'react';
import InputMask from 'react-input-mask';

import Str from '../../../helpers/Str';

export interface InputProps {
  className?: string;
  placeholder?: string;
  label?: string;
  error?: string;
  mask?: string | 'cm' | 'kg' | 'longitude' | 'latitude' | 'UF' | 'CEP' | 'CPF' | 'CNPJ' | 'clientCode' | 'phone' | 'cellphone' | 'currency' ;
  value?: string | number;
  inputType?: 'longitude' | 'latitude' | 'UF' | 'CEP' | 'CPF' | 'CNPJ' | 'clientCode' | 'password' | 'email' | 'time';
  setValue?(value: string): void;
  large?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
  type?: 'number' | 'date' | 'time';
  min?: number;
  name?: string;
  key?: string;
  required?: boolean;
  onBlur?: any;
  legenda?: string;
}

export default function Input({
  className,
  value,
  label,
  error,
  setValue,
  mask = '',
  inputType,
  placeholder,
  large,
  hideLabel,
  type,
  name,
  key,
  required,
  disabled,
  onBlur,
  legenda,
  ...rest
}: InputProps) {
  const finalPlaceholder = 
    inputType === 'longitude' ? 'Longitude' :
      inputType === 'latitude' ? 'Latitude' :
        inputType === 'UF' ? 'UF' :
          inputType === 'CEP' ? 'CEP' :
            inputType === 'CPF' ? 'CPF' :
              inputType === 'CNPJ' ? 'CNPJ' :
                inputType === 'clientCode' ? 'Código de cliente' : 
                  inputType === 'password' ? 'Senha' :
                    inputType === 'email' ? 'email' :
                    placeholder;

  const finalMask = (mask === 'CPF' || inputType === 'CPF') ? '999.999.999-99' : 
    (mask === 'cm') ? '99999,99' : 
      (mask === 'kg') ? '99999,99' : 
        (mask === 'phone') ? '(99) 9999-9999' : 
          (mask === 'cellphone') ? '(99) 9 9999-9999' : 
            (mask === 'cellphoneUSA') ? '(999) 999 9999' :
              (mask === 'longitude' || inputType === 'longitude') ? '' :
                (mask === 'latitude' || inputType === 'latitude') ? '' :
                  (mask === 'CEP' || inputType === 'CEP') ? '99.999-999' :
                    (mask === 'UF' || inputType === 'UF') ? 'aa' :
                      (mask === 'clientCode' || inputType === 'clientCode') ? '99.999.999/9999-99' :
                        (mask === 'CNPJ' || inputType === 'CNPJ') ? '99.999.999/9999-99' :
                          (mask === 'currency') ? '9.999.999.999.999,99' :
                          mask;

  const finalType = inputType === 'password' ? 'password' : type;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleOnChange(event: any): void {
    if (! setValue) return;

    let value = event.target.value as string;

    if (
      mask === 'CPF' || inputType === 'CPF' ||
      mask === 'clientCode' || inputType === 'clientCode' ||
      mask === 'CNPJ' || inputType === 'CNPJ' ||
      mask === 'CEP' || inputType === 'CEP' ||
      mask === 'longitude' || inputType === 'longitude' ||
      mask === 'latitude' || inputType === 'latitude' /*||
      mask === 'phone' || mask === 'cellphone'*/
    ) value = Str.removeNonNumbers(value);

    if (mask === 'cm' || mask === 'kg') {
      value = value.replaceAll(',__', '').replaceAll('_', ''); 
    }

    if (mask === 'UF' || inputType === 'UF') value = value.toUpperCase();

    let finalData = inputType === 'password' ? value : value.toUpperCase()
    if (type === 'date'){
      if (finalData.length <= 10){
        setValue(finalData);
      }
    }else{
      setValue(finalData);
    }


  }

  return (
    <label className={Str.tw('w-full text-gray-800', className)}>
      {!hideLabel && <span className='font-medium'>{label || finalPlaceholder} {required ? <span className={'text-[#c22929]'}>*</span> : ''}</span>}
      <InputMask
        disabled={disabled}
        required={required}
        key={key}
        {...rest}
        name={name}
        maskChar=""
        maxLength={type === 'date' ? 10 : 10000}
        type={finalType}
        mask={finalMask}
        placeholder={hideLabel ? finalPlaceholder : ''}
        value={value ? ( inputType === 'password' ? value :  (typeof value !== "number" ? value : value) ) : ''}
        onChange={handleOnChange}
        onBlur={onBlur}
        className={Str.tw(
          large ? 'py-3' : 'py-2',
          'bg-light border border-border rounded-md outline-none px-3 w-full',
          'focus:bg-background focus:border-confianca transition'
        )}
      />
      {!!error?.length && <span className="text-red-600 text-sm">{error}</span>}
      {
        legenda ?
            <div className={'text-sm text-gray-400'}>
              {legenda}
            </div> :''
      }

    </label>
  );
}