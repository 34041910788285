import React, {useEffect, useState} from "react";
import dadosContatoBrasilApi from "../../../../../services/dadosContatoBrasilApi";
import Alert from "../../../../../helpers/Alert";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import processosApi from "../../../../../services/processosApi";
import HeaderForm from "../../../../../components/headerForm";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import Input from "../../../../../components/input/Input";

export interface IDadosContatoBrasil{
    processo?: any;
    setTelaNumero?: any;
    telanumero?: any;
    setProcesso?: any;
}

export default function DadosContatoBrasil({processo, setTelaNumero, telanumero, setProcesso}: IDadosContatoBrasil) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        nome_primeiro: '',
        sobrenome_primeiro: '',
        endereco_primeiro: '',
        cidade_primeiro: '',
        estado_primeiro: '',
        cep_primeiro: '',
        telefone_primeiro: '',
        email_primeiro: '',
        nome_segundo: '',
        sobrenome_segundo: '',
        endereco_segundo: '',
        cidade_segundo: '',
        estado_segundo: '',
        cep_segundo: '',
        telefone_segundo: '',
        email_segundo: '',
    });

    useEffect(() => {
        getDadosContatoBr();

    }, []);


    const getDadosContatoBr = async () => {
        const {data, isError} = await dadosContatoBrasilApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }
    }

    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await dadosContatoBrasilApi.update(state.id, state) : await dadosContatoBrasilApi.create(state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };


    return(
        <>
            <HeaderForm
                titulo={'Dados da escola'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'text-2xl'}>
                        Contato nº1
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Nome'}
                            placeholder={'Nome'}
                            large={true}
                            name={'nome_primeiro'}
                            setValue={e => handleChange(e,'nome_primeiro')}
                            value={state.nome_primeiro}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Sobrenome'}
                            placeholder={'Sobrenome'}
                            large={true}
                            name={'sobrenome_primeiro'}
                            setValue={e => handleChange(e,'sobrenome_primeiro')}
                            value={state.sobrenome_primeiro}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Endereco'}
                            placeholder={'Endereco'}
                            large={true}
                            name={'endereco_primeiro'}
                            setValue={e => handleChange(e,'endereco_primeiro')}
                            value={state.endereco_primeiro}
                            required
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Cidade'}
                            placeholder={'Cidade'}
                            large={true}
                            name={'cidade_primeiro'}
                            setValue={e => handleChange(e,'cidade_primeiro')}
                            value={state.cidade_primeiro}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Estado/Província'}
                            placeholder={'Estado/Província'}
                            large={true}
                            name={'estado_primeiro'}
                            setValue={e => handleChange(e,'estado_primeiro')}
                            value={state.estado_primeiro}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'CEP'}
                            placeholder={'CEP'}
                            large={true}
                            name={'cep_primeiro'}
                            setValue={e => handleChange(e,'cep_primeiro')}
                            value={state.cep_primeiro}
                            required
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Telefone'}
                            placeholder={'Telefone'}
                            large={true}
                            mask={'cellphone'}
                            name={'telefone_primeiro'}
                            setValue={e => handleChange(e,'telefone_primeiro')}
                            value={state.telefone_primeiro}
                            required
                        />
                        <Input
                            className={'col-span-2'}
                            label={'E-mail'}
                            placeholder={'E-mail'}
                            large={true}
                            name={'email_primeiro'}
                            setValue={e => handleChange(e,'email_primeiro')}
                            value={state.email_primeiro}
                            required
                        />
                    </div>

                    <div className={'text-2xl mt-5'}>
                        Contato nº2
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Nome'}
                            placeholder={'Nome'}
                            large={true}
                            name={'nome_segundo'}
                            setValue={e => handleChange(e,'nome_segundo')}
                            value={state.nome_segundo}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Sobrenome'}
                            placeholder={'Sobrenome'}
                            large={true}
                            name={'sobrenome_segundo'}
                            setValue={e => handleChange(e,'sobrenome_segundo')}
                            value={state.sobrenome_segundo}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Endereco'}
                            placeholder={'Endereco'}
                            large={true}
                            name={'endereco_segundo'}
                            setValue={e => handleChange(e,'endereco_segundo')}
                            value={state.endereco_segundo}
                            required
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Cidade'}
                            placeholder={'Cidade'}
                            large={true}
                            name={'cidade_segundo'}
                            setValue={e => handleChange(e,'cidade_segundo')}
                            value={state.cidade_segundo}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Estado/Província'}
                            placeholder={'Estado/Província'}
                            large={true}
                            name={'estado_segundo'}
                            setValue={e => handleChange(e,'estado_segundo')}
                            value={state.estado_segundo}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'CEP'}
                            placeholder={'CEP'}
                            large={true}
                            name={'cep_segundo'}
                            setValue={e => handleChange(e,'cep_segundo')}
                            value={state.cep_segundo}
                            required
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Telefone'}
                            placeholder={'Telefone'}
                            large={true}
                            mask={'cellphone'}
                            name={'telefone_segundo'}
                            setValue={e => handleChange(e,'telefone_segundo')}
                            value={state.telefone_segundo}
                            required
                        />
                        <Input
                            className={'col-span-2'}
                            label={'E-mail'}
                            placeholder={'E-mail'}
                            large={true}
                            name={'email_segundo'}
                            setValue={e => handleChange(e,'email_segundo')}
                            value={state.email_segundo}
                            required
                        />
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}