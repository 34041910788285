import { Line } from 'rc-progress';
import Form from "../../../../../components/form/Form";
import React, {useState} from "react";
import Input from "../../../../../components/input/Input";
import InputSelect from "../../../../../components/input/InputSelect";
import ViaCepService from "../../../../../services/viaCepApi";
import Button from "../../../../../components/button/Button";
import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import clientesApi from "../../../../../services/clientesApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
export interface IDadosPessoaisEua{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DadosPessoaisEua({processo, setProcesso, setTelaNumero, telanumero}: IDadosPessoaisEua) {

    const [dadosPessoais, setDadosPessoais] = useState({
        id: processo.cliente_id,
        nome_responsavel: processo.cliente.nome_responsavel,
        telefone_responsavel: processo.cliente.telefone_responsavel,
        email_responsavel:  processo.cliente.email_responsavel,
        email:  processo.cliente.usuario.email,
        nome: processo.cliente.nome,
        sobrenome: processo.cliente.sobrenome,
        nomes_anteriores: processo.cliente.nomes_anteriores,
        nacionalidade: processo.cliente.nacionalidade,
        nascimento: processo.cliente.nascimento,
        sexo: processo.cliente.sexo ? processo.cliente.sexo : 'm',
        estado_civil: processo.cliente.estado_civil,
        cidade_natal: processo.cliente.cidade_natal,
        pais_natal: processo.cliente.pais_natal,
        outras_cidadanias: processo.cliente.outras_cidadanias,
        social_security: processo.cliente.social_security,
        cpf: processo.cliente.cpf,
        rg: processo.cliente.rg,
        pais_residencia: processo.cliente.pais_residencia,
        passaporte_encaminhado_email: processo.cliente.passaporte_encaminhado_email === null ? 0 : processo.cliente.passaporte_encaminhado_email,
        passaporte_encaminhado_residencia: processo.cliente.passaporte_encaminhado_residencia === null ? 0 : processo.cliente.passaporte_encaminhado_residencia,
        endereco_passaport: processo.cliente.endereco_passaport,
        telefone_trabalho: processo.cliente.telefone_trabalho,
        outros_telefone: processo.cliente.outros_telefone,
        telefone: processo.cliente.telefone,
        telefone_secundario: processo.cliente.telefone_secundario,
        outros_emails: processo.cliente.outros_emails,
        possui_responsavel: processo.cliente.possui_responsavel,
        cep: processo.cliente.cep,
        endereco: processo.cliente.endereco,
        bairro: processo.cliente.bairro,
        cidade: processo.cliente.cidade,
        estado: processo.cliente.estado,
        percet_conclusao: processo.percet_conclusao,
        rede_social: processo.rede_social,
    });
    const stadoCivilOptions = [
        { value: 'solteiro', label: 'Solteiro(a)' },
        { value: 'casado', label: 'Casado(a)' },
        { value: 'uniao estavel', label: 'União Estável' },
        { value: 'separado', label: 'Separado(a)' },
        { value: 'desquitado', label: 'Desquitado(a)' },
        { value: 'viuvo', label: 'Viúvo(a)' },
        { value: 'divorciado', label: 'Divorciado' },
    ]
    const handleChange = (value: any, input: string) => {
        setDadosPessoais({...dadosPessoais, [input]: value});
    };
    const cepSrv = new ViaCepService();
    const handleChangeCep = (value: any) => {
        cepSrv.consultaCep(dadosPessoais.cep).then((cepResponse: any) =>{
            if (!cepResponse.data.erro){
                setDadosPessoais({
                    ...dadosPessoais,
                    endereco: cepResponse.data.logradouro,
                    bairro: cepResponse.data.bairro,
                    cidade: cepResponse.data.localidade,
                    estado: cepResponse.data.uf,
                });
            }
        });
    }

    async function onSubmit() {
        Alert.await("Salvando dados");
        const data = await clientesApi.update(dadosPessoais.id, dadosPessoais);
        if (data.isError){
            if (data.data.message){
                return Alert.error(data.data.message? data.data.message : data.data);
            }
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        let dataProcesso: any = {
            'rede_social': dadosPessoais.rede_social
        }
        let per = 0;
        if (processo.percet_conclusao === 0){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            dataProcesso['percet_conclusao'] = percent;
            per = percent;
        }
        processosApi.update(processo.id, dataProcesso)
        const cliente = processo.cliente;
        cliente.estado_civil = dadosPessoais.estado_civil;
        setProcesso({...processo, 'cliente': cliente, percet_conclusao:per })
        Alert.close();
        window.scrollTo(0, 0);
        let numero = telanumero + 1;
        if (dadosPessoais.estado_civil === 'solteiro') {
            numero = telanumero + 2;
        }
        setTelaNumero(numero);
        //setTelaNumero(8);
    }



    return(
        <div>
            <HeaderForm
                titulo={'Dados Pessoais'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={dadosPessoais.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Nome '}
                            placeholder={'Nome'}
                            large={true}
                            name={'nome'}
                            setValue={e => handleChange(e,'nome')}
                            value={dadosPessoais.nome}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Sobrenome '}
                            placeholder={'Sobrenome'}
                            large={true}
                            name={'sobrenome'}
                            setValue={e => handleChange(e,'sobrenome')}
                            value={dadosPessoais.sobrenome}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Nomes anteriores'}
                            placeholder={'Nomes anteriores'}
                            large={true}
                            name={'nomes_anteriores'}
                            setValue={e => handleChange(e,'nomes_anteriores')}
                            value={dadosPessoais.nomes_anteriores}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            inputType={'email'}
                            className={'col-span-1'}
                            label={'E-mail '}
                            placeholder={'E-mail'}
                            large={true}
                            name={'email'}
                            setValue={e => handleChange(e,'email')}
                            value={dadosPessoais.email}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Você usou outros e-mails nos últimos cinco anos'}
                            placeholder={'Você usou outros e-mails nos últimos cinco anos'}
                            large={true}
                            name={'outros_emails'}
                            setValue={e => handleChange(e,'outros_emails')}
                            value={dadosPessoais.outros_emails}
                        />
                        <div>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Sexo
                                </label>
                                <div className={'flex mt-[1.5rem] gap-4'}>
                                    <div className={'flex'}>
                                        <input

                                            onChange={e => handleChange(e.target.value,'sexo')}
                                            value="m"
                                            id="checked-sexo-m"
                                            type="radio"
                                            checked={dadosPessoais.sexo === "m" || dadosPessoais.sexo === ""}
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-sexo-m"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Masculino
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={dadosPessoais.sexo === "f"}
                                            onChange={e => handleChange(e.target.value,'sexo')}
                                            id="checked-sexo-f"
                                            type="radio"
                                            value="f"
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-sexo-f"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Feminino
                                        </label>
                                    </div>


                                </div>

                            </div>

                        </div>
                    </div>
                    <div className={'grid grid-cols-1 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Você possui conta em alguma rede social? Se sim, informar a plataforma e nome de usuário '}
                            placeholder={'Você possui conta em alguma rede social? Se sim, informar a plataforma e nome de usuário'}
                            name={'rede_social'}
                            large={true}
                            setValue={e => handleChange(e,'rede_social')}
                            value={dadosPessoais.rede_social}
                            required
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Nacionalidade '}
                            placeholder={'Nacionalidade'}
                            large={true}
                            name={'nacionalidade'}
                            setValue={e => handleChange(e,'nacionalidade')}
                            value={dadosPessoais.nacionalidade}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Data de nascimento '}
                            type={'date'}
                            placeholder={'Data de nascimento'}
                            large={true}
                            name={'nascimento'}
                            setValue={e => handleChange(e,'nascimento')}
                            value={dadosPessoais.nascimento}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Cidade / Estado natal '}
                            placeholder={'Cidade / Estado natal'}
                            large={true}
                            name={'cidade_natal'}
                            setValue={e => handleChange(e,'cidade_natal')}
                            value={dadosPessoais.cidade_natal}
                            required
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'País natal '}
                            placeholder={'País natal'}
                            large={true}
                            name={'pais_natal'}
                            setValue={e => handleChange(e,'pais_natal')}
                            value={dadosPessoais.pais_natal}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Outras cidadanias'}
                            placeholder={'Outras cidadanias'}
                            large={true}
                            name={'outras_cidadanias'}
                            setValue={e => handleChange(e,'outras_cidadanias')}
                            value={dadosPessoais.outras_cidadanias}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'CPF '}
                            placeholder={'CPF'}
                            inputType={"CPF"}
                            large={true}
                            name={'cpf'}
                            setValue={e => handleChange(e,'cpf')}
                            value={dadosPessoais.cpf}
                            required
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'RG/ID'}
                            placeholder={'RG/ID'}
                            large={true}
                            name={'rg'}
                            setValue={e => handleChange(e,'rg')}
                            value={dadosPessoais.rg}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Você tem ou já teve um Social Securty Number?'}
                            placeholder={'Você tem ou já teve um Social Securty Number?'}
                            large={true}
                            name={'social_security'}
                            setValue={e => handleChange(e,'social_security')}
                            value={dadosPessoais.social_security}
                        />
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Estado civil"}
                                selected={stadoCivilOptions.filter((opt: any) => opt.value === dadosPessoais.estado_civil)}
                                options={stadoCivilOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'estado_civil')}
                                required
                            />
                        </div>

                    </div>
                    <div className={'py-5 text-[25px] font-bold'}>
                        Endereço
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'CEP'}
                            placeholder={'CEP'}
                            inputType={'CEP'}
                            large={true}
                            name={'cep'}
                            setValue={e => handleChange(e,'cep')}
                            onBlur={(e: any) => handleChangeCep(e)}
                            value={dadosPessoais.cep}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Endereço'}
                            placeholder={'Endereço'}
                            large={true}
                            name={'endereco'}
                            setValue={e => handleChange(e,'endereco')}
                            value={dadosPessoais.endereco}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Bairro'}
                            placeholder={'Bairro'}
                            large={true}
                            name={'bairro'}
                            setValue={e => handleChange(e,'bairro')}
                            value={dadosPessoais.bairro}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Cidade'}
                            placeholder={'Cidade'}
                            large={true}
                            name={'cidade'}
                            setValue={e => handleChange(e,'cidade')}
                            onBlur={(e: any) => handleChangeCep(e)}
                            value={dadosPessoais.cidade}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Estado'}
                            placeholder={'Estado'}
                            large={true}
                            name={'estado'}
                            setValue={e => handleChange(e,'estado')}
                            value={dadosPessoais.estado}
                            required
                        />

                    </div>

                    <div className={'flex flex-col my-[24px]'}>
                        <label
                            className="text-[16px] font-medium text-gray-900 dark:text-gray-300"
                        >
                            Seu passaporte será encaminhado para o endereço residencial?
                        </label>
                        <div className={'flex  gap-4'}>
                            <div className={'flex'}>
                                <input
                                    onChange={e => handleChange(1,'passaporte_encaminhado_residencia')}
                                    value={1}
                                    id="checked-passaporte_encaminhado_email-1"
                                    type="radio"
                                    checked={dadosPessoais.passaporte_encaminhado_residencia === 1 }
                                    className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                    htmlFor="checked-passaporte_encaminhado_email-1"
                                    className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Sim
                                </label>
                            </div>
                            <div className={'flex'}>
                                <input
                                    checked={dadosPessoais.passaporte_encaminhado_residencia === 0 }
                                    onChange={e => handleChange(0,'passaporte_encaminhado_residencia')}
                                    id="checked-passaporte_encaminhado_email"
                                    type="radio"
                                    value={0}
                                    className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                    htmlFor="checked-passaporte_encaminhado_email"
                                    className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Não
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={'grid md:grid-cols-1 mt-5'}>
                        <Input
                            label={'Caso a resposta seja não, favor informar o endereço alterantivo completo com CEP'}
                            placeholder={'Caso a resposta seja não, favor informar o endereço alterantivo completo com CEP'}
                            large={true}
                            name={'endereco_passaport'}
                            setValue={e => handleChange(e,'endereco_passaport')}
                            value={dadosPessoais.endereco_passaport}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            label={'Telefone  '}
                            placeholder={'Telefone'}
                            large={true}
                            mask={'cellphone'}
                            name={'telefone'}
                            setValue={e => handleChange(e,'telefone')}
                            className={'col-span-1'}
                            value={dadosPessoais.telefone}
                            required
                        />
                        <Input
                            label={'Telefone secundário'}
                            placeholder={'Telefone secundário'}
                            large={true}
                            mask={'cellphone'}
                            name={'telefone_secundario'}
                            setValue={e => handleChange(e,'telefone_secundario')}
                            className={'col-span-1'}
                            value={dadosPessoais.telefone_secundario}
                        />
                        <Input
                            label={'Telefone do trabalho'}
                            placeholder={'Telefone do trabalho'}
                            large={true}
                            mask={'cellphone'}
                            name={'telefone_trabalho'}
                            setValue={e => handleChange(e,'telefone_trabalho')}
                            className={'col-span-1'}
                            value={dadosPessoais.telefone_trabalho}
                        />
                    </div>
                    <div className={'grid grid-cols-1  mt-5'}>
                        <Input
                            label={'Você já usou outros números de telefone nos útimos cinco anos? '}
                            placeholder={'Você já usou outros números de telefone nos útimos cinco anos?'}
                            large={true}
                            name={'outros_telefone'}
                            setValue={e => handleChange(e,'outros_telefone')}
                            value={dadosPessoais.outros_telefone}
                        />
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-end p-5'}>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>

                </div>
            </Form>
        </div>
    )
}