import React, {useEffect, useState} from "react";
import conjugeApi from "../../../../../services/conjugeApi";
import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Input from "../../../../../components/input/Input";
import ViaCepService from "../../../../../services/viaCepApi";
import Button from "../../../../../components/button/Button";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";

export interface IDadosConjugeEua{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DadosConjugeEua({processo, setProcesso, setTelaNumero, telanumero}: IDadosConjugeEua) {
    const [state, setState] = useState({
        id: '',
        cliente_id: processo.cliente_id,
        nome: '',
        sobrenome: '',
        nascimento: '',
        endereco: '',
        bairro: '',
        cep: '',
        complemento: '',
        cidade: '',
        estado: '',
        telefone: '',
        viaja_junto: 1,
        informacoes_nao_casados: '',
        cidade_resideincia: '',
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        const {data, isError} = await conjugeApi.one(processo.cliente_id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }
        setLoading(false);
    }

    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const cepSrv = new ViaCepService();
    const handleChangeCep = (value: any) => {
        cepSrv.consultaCep(state.cep).then((cepResponse: any) =>{
            if (!cepResponse.data.erro){
                setState({
                    ...state,
                    endereco: cepResponse.data.logradouro,
                    bairro: cepResponse.data.bairro,
                    cidade_resideincia: cepResponse.data.localidade,
                    estado: cepResponse.data.uf,
                });
            }
        });
    }

    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await conjugeApi.update(state.id, state) : await conjugeApi.create(state);

        if (isError){
            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.message? data.message : data);
        }
        /*if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }*/
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        let numero = telanumero + 1;
        setTelaNumero(numero);
    }

    return(
       <>
           <HeaderForm
               titulo={'Dados do cônjuge'}
               numeroPaginalAtual={telanumero}
               numeroTotal={quantidade[processo.tipo_visto_id]}
               percentual={processo.percet_conclusao}
           />
           <Form  onSubmit={() => onSubmit()}>
               <div className={'p-5'}>
                   <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                       <Input
                           className={'col-span-1'}
                           label={'Nome '}
                           placeholder={'Nome'}
                           large={true}
                           name={'nome'}
                           setValue={e => handleChange(e,'nome')}
                           value={state.nome}
                           required
                       />
                       <Input
                           className={'col-span-1'}
                           label={'Sobrenome '}
                           placeholder={'Sobrenome'}
                           large={true}
                           name={'sobrenome'}
                           setValue={e => handleChange(e,'sobrenome')}
                           value={state.sobrenome}
                           required
                       />
                       <Input
                           className={'col-span-1'}
                           label={'Data de nascimento '}
                           type={'date'}
                           placeholder={'Data de nascimento'}
                           large={true}
                           name={'nascimento'}
                           setValue={e => handleChange(e,'nascimento')}
                           value={state.nascimento}
                           required
                       />
                   </div>
                   <div className={'py-5 mt-1 text-[25px] font-bold'}>
                       Endereço
                   </div>
                   <div className={'grid md:grid-cols-3 gap-4 '}>
                       <Input
                           className={'col-span-1'}
                           label={'Cidade de nascimento do Cônjuge '}
                           placeholder={'Cidade de nascimento do Cônjuge'}
                           large={true}
                           name={'cidade'}
                           setValue={e => handleChange(e,'cidade')}
                           value={state.cidade}
                           required
                       />
                       <Input
                           className={'col-span-1'}
                           label={'CEP'}
                           placeholder={'CEP'}
                           inputType={'CEP'}
                           large={true}
                           name={'cep'}
                           setValue={e => handleChange(e,'cep')}
                           onBlur={(e: any) => handleChangeCep(e)}
                           value={state.cep}
                       />
                       <Input
                           className={'col-span-1'}
                           label={'Endereço'}
                           placeholder={'Endereço'}
                           large={true}
                           name={'endereco'}
                           setValue={e => handleChange(e,'endereco')}
                           value={state.endereco}
                       />

                   </div>
                   <div className={'grid md:grid-cols-3 mt-4 gap-4 '}>
                       <Input
                           className={'col-span-1'}
                           label={'Bairro'}
                           placeholder={'Bairro'}
                           large={true}
                           name={'bairro'}
                           setValue={e => handleChange(e,'bairro')}
                           value={state.bairro}
                       />
                       <Input
                           className={'col-span-1'}
                           label={'Cidade de residência '}
                           placeholder={'Cidade de residência'}
                           large={true}
                           name={'cidade_resideincia'}
                           setValue={e => handleChange(e,'cidade_resideincia')}
                           value={state.cidade_resideincia}
                           required
                       />
                       <Input
                           className={'col-span-1'}
                           label={'Estado '}
                           placeholder={'Estado'}
                           large={true}
                           name={'estado'}
                           setValue={e => handleChange(e,'estado')}
                           value={state.estado}
                       />
                   </div>
                   <div className={'grid md:grid-cols-3 mt-4 gap-4 '}>
                       <Input
                           label={'Telefone'}
                           placeholder={'Telefone'}
                           large={true}
                           mask={'cellphone'}
                           name={'telefone'}
                           setValue={e => handleChange(e,'telefone')}
                           className={'col-span-1'}
                           value={state.telefone}
                       />
                       <div className={'col-span-1'}>
                           <div className={'flex flex-col my-[24px]'}>
                               <label
                                   className="text-[16px] font-medium text-gray-900 dark:text-gray-300"
                               >
                                   Viaja juntos?
                               </label>
                               <div className={'flex  gap-4'}>
                                   <div className={'flex'}>
                                       <input
                                           onChange={e => handleChange(1,'viaja_junto')}
                                           value={1}
                                           id="checked-viaja_junto-s"
                                           type="radio"
                                           checked={state.viaja_junto === 1 }
                                           className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                       />
                                       <label
                                           htmlFor="checked-viaja_junto-s"
                                           className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                       >
                                           Sim
                                       </label>
                                   </div>
                                   <div className={'flex'}>
                                       <input
                                           checked={state.viaja_junto === 0 }
                                           onChange={e => handleChange(0,'viaja_junto')}
                                           id="checked-viaja_junto-n"
                                           type="radio"
                                           value={0}
                                           className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                       />
                                       <label
                                           htmlFor="checked-viaja_junto-n"
                                           className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                       >
                                           Não
                                       </label>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
                   <div className={'grid  mt-4 gap-4 '}>
                       <Input
                           label={'Para pessoas separadas, divorciadas e desquitadas, por favor informar data de início e término da relação'}
                           placeholder={'Para pessoas separadas, divorciadas e desquitadas, por favor informar data de início e término da relação'}
                           large={true}
                           name={'informacoes_nao_casados'}
                           setValue={e => handleChange(e,'informacoes_nao_casados')}
                           className={'col-span-1'}
                           value={state.informacoes_nao_casados}
                       />
                   </div>
               </div>
               <div className={'border-2'}></div>
               <div className={'flex justify-between p-5'}>
                   <Button
                       onClick={() => setTelaNumero(1)}
                       className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                   >
                       Voltar
                   </Button>
                   <Button
                       type={'submit'}
                       className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                   >
                       Continuar
                   </Button>
               </div>
           </Form>
       </>
    )
}