import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import React, {useEffect, useState} from "react";
import empregosAnterioresApi from "../../../../../services/empregosAnterioresApi";
import processosApi from "../../../../../services/processosApi";
import Alert from "../../../../../helpers/Alert";
import Input from "../../../../../components/input/Input";
import ViaCepService from "../../../../../services/viaCepApi";

export interface IEmpregosAnteriores{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}
export default function EmpregosAnteriores({processo, setProcesso, setTelaNumero, telanumero}: IEmpregosAnteriores) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        nome_empregador: '',
        bairro: '',
        endereco: '',
        cidade: '',
        estado: '',
        cep: '',
        pais: '',
        telefone: '',
        cargo: '',
        nome_supervisor: '',
        sobrenome_supervisor: '',
        data_inicio: '',
        data_fim: '',
        descricao: '',
    });
    const cepSrv = new ViaCepService();
    useEffect(() => {getEmpregosAnteriores()}, [])
    const getEmpregosAnteriores = async () => {
        const {data, isError} = await empregosAnterioresApi.one(processo.id);
        if (!isError) {
            if (Object.values(data.data).length) {
                setState(data.data)
            }
        }
    }
    async function onSubmit() {
        const {data, isError} =  state.id ? await empregosAnterioresApi.update(state.id, state) : await empregosAnterioresApi.create(state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero+1);
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const handleChangeCep = (value: any) => {
        cepSrv.consultaCep(state.cep).then((cepResponse: any) =>{
            if (!cepResponse.data.erro){
                setState({
                    ...state,
                    endereco: cepResponse.data.logradouro,
                    bairro: cepResponse.data.bairro,
                    cidade: cepResponse.data.localidade,
                    estado: cepResponse.data.uf,
                    pais: 'Brasil',
                });
            }
        });
    }
    return(
        <>
            <HeaderForm
                titulo={'Empregos anteriores'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />

            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            required={true}
                            className={'col-span-1'}
                            label={'Nome do empregador anterior'}
                            name={'nome_empregador'}
                            setValue={e => handleChange(e, 'nome_empregador')}
                            value={state.nome_empregador}
                        />
                        <Input
                            required={true}
                            className={'col-span-1'}
                            label={'Primeiro Nome do(a) Supervisor(a)'}
                            name={'nome_supervisor'}
                            setValue={e => handleChange(e, 'nome_supervisor')}
                            value={state.nome_supervisor}
                        />
                        <Input
                            required={true}
                            className={'col-span-1'}
                            label={'Sobrenome do(a) Supervisor(a)'}
                            name={'sobrenome_supervisor'}
                            setValue={e => handleChange(e, 'sobrenome_supervisor')}
                            value={state.sobrenome_supervisor}
                        />
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            label={'CEP'}
                            name={'cep'}
                            mask={"CEP"}
                            setValue={e => handleChange(e, 'cep')}
                            onBlur={(e: any) => handleChangeCep(e)}
                            value={state.cep}
                        />
                        <Input
                            required={true}
                            className={'col-span-2'}
                            label={'Endereço'}
                            name={'endereco'}
                            setValue={e => handleChange(e, 'endereco')}
                            value={state.endereco}
                        />
                        <Input
                            required={true}
                            className={'col-span-1'}
                            label={'Bairro'}
                            name={'bairro'}
                            setValue={e => handleChange(e, 'bairro')}
                            value={state.bairro}
                        />
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <Input
                            required={true}
                            className={'col-span-1'}
                            label={'Cidade'}
                            name={'cidade'}
                            setValue={e => handleChange(e, 'cidade')}
                            value={state.cidade}
                        />
                        <Input
                            required={true}
                            className={'col-span-1'}
                            label={'Estado'}
                            name={'estado'}
                            setValue={e => handleChange(e, 'estado')}
                            value={state.estado}
                        />
                        <Input
                            required={true}
                            className={'col-span-1'}
                            label={'País'}
                            name={'pais'}
                            setValue={e => handleChange(e, 'pais')}
                            value={state.pais}
                        />
                        <Input
                            required={true}
                            className={'col-span-1'}
                            mask={'cellphone'}
                            label={'Telefone'}
                            name={'telefone'}
                            setValue={e => handleChange(e, 'telefone')}
                            value={state.telefone}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            required={true}
                            className={'md:col-span-1'}
                            label={'Cargo'}
                            name={'cargo'}
                            setValue={e => handleChange(e, 'cargo')}
                            value={state.cargo}
                        />
                        <Input
                            required={true}
                            className={'col-span-1'}
                            label={'Data de início do trabalho'}
                            name={'data_inicio'}
                            type={'date'}
                            setValue={e => handleChange(e, 'data_inicio')}
                            value={state.data_inicio}
                        />
                        <Input
                            required={true}
                            className={'col-span-1'}
                            label={'Data de término do trabalho'}
                            name={'data_fim'}
                            type={'date'}
                            setValue={e => handleChange(e, 'data_fim')}
                            value={state.data_fim}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'mt-3 flex flex-col'}>
                            <label
                                className="text-sm font-medium "
                            >
                                Breve descrição das atividade
                            </label>
                            <textarea
                                required={true}
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'descricao'}
                                onChange={e => handleChange(e.target.value.toUpperCase(),'descricao')}
                                value={state.descricao ? state.descricao : ""}
                            />
                        </div>
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}