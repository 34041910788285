import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import menus, { Menus } from '../../../router/menus';
import menusAtendente from '../../../router/menusAtendentes';
import menusParceiros from '../../../router/menusParceiros';
import { Link, useLocation } from "react-router-dom";
import {createElement, useEffect, useState} from "react";
import logo from '../../../assets/img/logo.png'
import { IoIosLogOut } from "react-icons/io";
import authApi from "../../../services/authApi";
import Storage from "../../../helpers/Storage";
import HomeSvg from 'assets/icone/home.svg';
export default function SideBar() {
    //https://azouaoui-med.github.io/react-pro-sidebar/?path=/docs/submenu--root-styles
    //https://www.npmjs.com/package/react-pro-sidebar
    const { pathname: currentPath } = useLocation();
    const validatePage = currentPath.split('/')[1];
    const userData: any = Storage.getUserData();
    const [menuData, setMenuData] = useState(menus);
    async function logoutUser() {
        await authApi.logout();
        Storage.removeApiToken();
        Storage.removeUserData();
        window.location.href = '/';
    }

    useEffect(()=>{
        switch (userData.usuario.tipo_id) {
            case 2:
                setMenuData(menusAtendente);
                break;
            case 3:
                setMenuData(menusParceiros);
                break;
            default:
                setMenuData(menus);
        }
    }, [])

    return(

        <div className={'flex h-screen bg-[#061F46] max-[800px]:hidden'}>

            <Sidebar
                backgroundColor="#061F46"
                rootStyles={{
                    borderColor: '#092a5e'
                }}
            >
                <Menu
                    menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            if (active){
                                return {
                                    color: "#30C55A",
                                    '&:hover': {
                                        backgroundColor: '#092a5e',
                                        color: "#30C55A",
                                    },

                                }
                            }
                            return {
                                color: "#fff",
                                '&:hover': {
                                    backgroundColor: '#092a5e',
                                    color: "#fff",
                                }
                            }

                            // only apply styles on first level elements of the tree
                            /*if (level === 0)
                                return {
                                    color: disabled ? '#f5d9ff' : '#d359ff',
                                    backgroundColor: active ? '#eecef9' : undefined,
                                };*/
                        },
                    }}
                    //cor do icone
                    rootStyles={{
                        [`.${menuClasses.icon}`]: {
                            color: '#30C55A',
                            fontSize: '25px'
                        },
                    }}
                >
                    <aside className="w-auto flex justify-center mt-5">
                        <img src={logo} className="" />
                    </aside>
                    <div className={'mt-5'}>
                        {
                            menuData.map((menu: any, index: number) => {

                                if (menu.subMenus) {
                                    return (
                                        <SubMenu
                                            key={menu.title+index}
                                            icon={createElement(menu.icon)}
                                            label={menu.title}
                                            rootStyles={{
                                                     ['& > .' + menuClasses.button]: {
                                                         backgroundColor: '#061F46',
                                                         color: '',
                                                         '&:hover': {
                                                             backgroundColor: '#092a5e',
                                                         },
                                                         fontSize: '16px'
                                                     },
                                                     ['.' + menuClasses.subMenuContent]: {
                                                         backgroundColor: '#0b3675',
                                                     },
                                                 }}
                                        >
                                            {
                                                menu.subMenus.map((subMenu: any, indexSub: number) => {
                                                    return(
                                                        <MenuItem
                                                            key={subMenu.title+indexSub}
                                                            component={<Link to={subMenu.path}/>}
                                                            active={currentPath === subMenu.path}
                                                        >
                                                            {subMenu.title}
                                                        </MenuItem>)
                                                })
                                            }

                                        </SubMenu>
                                    )
                                } else {
                                    return <MenuItem
                                        icon={createElement(menu.icon)}
                                        component={<Link to={menu.path} />}
                                        active={'/'+validatePage === menu.path}
                                    >
                                        {menu.title}
                                    </MenuItem>
                                }
                            })
                        }
                    </div>

                </Menu>
                <div className={'flex py-5 justify-center  bottom-0 w-[33vh]  absolute'}>
                    <button onClick={logoutUser} className="bg-[#E4EDFC33] hover:bg-[#c9d3e533] text-white font-bold py-5 px-20 rounded">
                        <div className={'flex'}>
                            <IoIosLogOut color={'#30C55A'} size={25} className={'mr-2'} /> Sair
                        </div>
                    </button>
                </div>
            </Sidebar>
        </div>

    )
}