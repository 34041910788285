import React, {createElement, useEffect, useState} from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import {IoIosLogOut, IoMdNotificationsOutline} from "react-icons/io";
import Dropdown from "../../dropdown/Dropdown";
import { IoIosArrowDown } from "react-icons/io";
import Storage from '../../../helpers/Storage';
import useAppContext from "../../../hooks/useAppContext";
import authApi from "../../../services/authApi";
import Modal from '../../../components/modal/Modal';
import useModal from '../../../hooks/useModal';
import {ChangePassword, QueryParams} from "../../../types";
import Form from "../../form/Form";
import Input from "../../input/Input";
import Button from "../../button/Button";
import Alert from "../../../helpers/Alert";
import menus, { Menus } from '../../../router/menus';
import logo from "../../../assets/img/logo.png";
import {useLocation, useNavigate} from "react-router-dom";
import cronogramaApi from "../../../services/cronogramaApi";
import notificacoesApi from "../../../services/notificacoesApi";
export interface INavBar {
    pageTitle?: string | React.ReactNode;
    pageTitleSecond?: string;
    admin?: boolean;
}
const Navbar = ({ pageTitle, pageTitleSecond, admin }: INavBar) => {
    const navigate = useNavigate();
    const { pathname: currentPath } = useLocation();
    const { modalProps, open, close } = useModal();
    // State to manage the navbar's visibility
    const [nav, setNav] = useState(false);
    const [dataDropDown, setDataDropDown]: any = useState([]);
    const [loadingNotificacoes, setloadingNotificacoes] = useState(true);
    // Toggle function to handle the navbar's display
    const handleNav = () => {
        setNav(!nav);
    };

    const [dataPassword, setDataPassword] = useState<ChangePassword>(
        {
            atualPassword: '',
            novoPassword: '',
            repetirPassword: '',
        }
    );

    const [dataPaginate, setDataPaginate] = useState({
        current_page: 1,
        last_page: 2,
        total_data: 0,
        per_page: 0,
    });

    const userData = Storage.getUserData();
    const appContext = useAppContext();

    useEffect(() => {
        if (userData) {
            appContext.setUsuario(userData.usuario);
            if (userData.usuario.tipo_id != 4){
                getDataNotificacoes();
            }
        }
    }, []);

    async function logoutUser() {
        await authApi.logout();
        Storage.removeApiToken();
        Storage.removeUserData();
        window.location.href = '/';
    }

    async function changePassword() {
        if (dataPassword.novoPassword != dataPassword.repetirPassword) {
          Alert.error('Senhas São diferentes');
          return;
        }
        //salva a senha
        const {data, isError} = await authApi.changePassword(userData?.usuario.id, dataPassword);

        if (isError){
            return Alert.error(data.message? data.message : data);
        }
        Alert.success('Senha alterada com sucesso!');
        close();
    }

    async function getDataNotificacoes(){
        setloadingNotificacoes(true);
        const [notificacoes] = await Promise.all([notificacoesApi.listAll()]);
        if (!notificacoes.isError){

            const dataDropDownlocal = notificacoes.data.map((notificacao: any) => {
                return({
                    id: notificacao.id,
                    label: notificacao.notificacao,
                    status: true,
                })
            })
            setDataDropDown(dataDropDownlocal)
        }
        setloadingNotificacoes(false);
    }

    const onPageChangeDropDown = (e: number) => {

    }

    const onSelectDropDown = (e: any) => {
        console.log(e);
    }

    const visualizou = async (e: any) => {
        const {data, isError} = await notificacoesApi.visualizou(e.id);
        getDataNotificacoes();
    }

    return (
        <>
            <div className={`bg-[${admin ? '#F9FAFB' : '#061f46'}] h-[85px] flex justify-between px-[3rem] items-center`}>
                {
                    admin ?
                        <div className={'flex items-center w-full'}>
                            {typeof pageTitle === 'string' ? <h1 className='text-3xl text-gray-800'>{pageTitle}</h1> : pageTitle}
                            <h1 className='text-[16px] px-5 text-gray-800'>{pageTitleSecond}</h1>
                        </div>
                        :
                        <aside className="w-auto flex justify-center cursor-pointer px-3" onClick={() => navigate('/processos')}>
                            <img src={logo} className="" />
                        </aside>
                }


            {/* Desktop Navigation */}
            <ul className='hidden md:flex px-5 w-[370px] justify-end z-[100]'>
                {
                    admin ?
                        <li>

                            <div className={'flex items-center h-full '}>
                                <Dropdown
                                    onPageChange={onPageChangeDropDown}
                                    onSelectDropDown={onSelectDropDown}
                                    consult={true}
                                    onClickItem={(e: any)=> visualizou(e)}
                                    trigger={
                                        <button className={'flex w-[187px] h-[48px] bg-[#e4edfc] justify-center items-center font-bold mr-5'}>
                                            <IoMdNotificationsOutline size={25} />
                                            <div className='h-[13px] w-[13px] bg-[#c22929] rounded-[50%] border-2 inline-block relative top-[-7px] left-[-14px]'></div>
                                            {dataDropDown.length} Atualizações
                                        </button>
                                    }
                                    items={dataDropDown}
                                    loading={loadingNotificacoes}
                                />
                            </div>
                        </li>
                        :
                        ''
                }

                <li>
                    <div className={'flex items-center h-full '}>
                        <Dropdown
                            trigger={
                                <div className={`flex h-[40px] justify-center items-center py-2  rounded-md font-semibold ${admin ? '' : 'text-white'}`}>
                                    {userData?.usuario?.nome} <IoIosArrowDown size={20} className={'ml-2'} />
                                </div>
                            }
                            items={[
                                { label: 'Alterar Senha', onClick: () => open() },
                                { label: 'Sair', onClick: () => logoutUser()}
                            ]}
                        />
                    </div>

                </li>
            </ul>

            {/* Mobile Navigation Icon */}
            <div onClick={handleNav} className='block md:hidden mr-5'>
                {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
            </div>

            {/* Mobile Navigation Menu */}
            {
                admin ?
                    <ul
                        className={
                            nav
                                ? 'fixed md:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#061F46] ease-in-out duration-500 z-[100]'
                                : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%] z-[100]'
                        }
                    >
                        {/* Mobile Logo */}
                        <div>
                            <aside key={3849752934} className="w-auto flex justify-center mt-5">
                                <img src={logo} className="" />
                            </aside>
                        </div>

                        {/* Mobile Navigation Items */}

                        {menus.map((menu: any, index: number) => (
                            <li
                                key={index}
                                onClick={() => navigate(menu.path)}
                                className={`
                        flex text-[#fff] p-4 border-b hover:bg-[#092a5e] duration-300  cursor-pointer border-gray-600
                        ${currentPath === menu.path ? 'text-[#30C55A]' : ''}
                        `}
                            >
                                <div className={'text-[#30C55A] text-[20px] mr-2'}>{createElement(menu.icon)}</div>{menu.title}
                            </li>
                        ))}
                        <li
                            key={456748}
                            className='flex'
                        >
                            <div className={'flex py-5 justify-center fixed bottom-0 w-[33vh]  '}>
                                <button onClick={logoutUser} className="bg-[#E4EDFC33] hover:bg-[#c9d3e533] text-white font-bold py-3 px-20 rounded">
                                    <div className={'flex'}>
                                        <IoIosLogOut color={'#30C55A'} size={25} className={'mr-2'} /> Sair
                                    </div>
                                </button>
                            </div>
                        </li>
                    </ul>
                    : ''
            }

        </div>
            <Modal size="lg" title="Alterar Senha" {...modalProps}>
                <Form onSubmit={changePassword}>
                    <div className={'p-3 flex flex-col'}>
                        <Input
                            required={true}
                            label="Senha"
                            inputType='password'
                            value={dataPassword.atualPassword}
                            setValue={(atualPassword) => setDataPassword({ ...dataPassword, atualPassword })}
                        />

                        <Input
                            required={true}
                            className={'mt-4'}
                            label="Nova Senha"
                            inputType='password'
                            value={dataPassword.novoPassword}
                            setValue={(novoPassword) => setDataPassword({ ...dataPassword, novoPassword })}
                        />

                        <Input
                            required={true}
                            className={'mt-4'}
                            label="Repetir Senha"
                            inputType='password'
                            value={dataPassword.repetirPassword}
                            setValue={(repetirPassword) => setDataPassword({ ...dataPassword, repetirPassword })}
                        />
                    </div>
                    <div className={'p-3 flex '}>
                        <Button type='submit' >
                            Salvar
                        </Button>
                        <Button onClick={close} className={'ml-5'}>
                            Cancelar
                        </Button>
                    </div>

                </Form>
            </Modal>
        </>
    );
};

export default Navbar;