import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import React, {useEffect, useState} from "react";
import dadosResponsavelApi from "../../../../../services/dadosResponsavelApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import Input from "../../../../../components/input/Input";

export interface IAutorizacaoMenorAusEstudante{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function AutorizacaoMenorAusEstudante({processo, setProcesso, setTelaNumero, telanumero}: IAutorizacaoMenorAusEstudante) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        aplicante_menor: 0,
        nome: '',
        sobrenome: '',
        endereco: '',
        cep: '',
        telefone: '',
        celular: '',
        grau_parentesco: '',
        passaporte_rg: '',
        segundo_nome: '',
        segundo_sobrenome: '',
        segundo_endereco: '',
        segundo_cep: '',
        segundo_telefone: '',
        segundo_celular: '',
        segundo_grau_parentesco: '',
        segundo_passaporte_rg: '',
        guardiao_viaja: 0,
        guardiao_autoriza: 0,
        guardiao_ciente: 0,
    });
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const getDadosResponsaveis = async () => {
        const {data, isError} = await dadosResponsavelApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }
    }
    useEffect(()=>{getDadosResponsaveis()}, [])
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await dadosResponsavelApi.update(state.id, state) : await dadosResponsavelApi.create(state);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        let numero = telanumero + 1;
        setTelaNumero(numero);
    }
    return(
        <>
            <HeaderForm
                titulo={'Autorização de menores viajando desacompanhados'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col md:col-span-1'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                O aplicante principal é menor de idade?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'aplicante_menor')}
                                        value={1}
                                        id="checked-aplicante_menor-s"
                                        type="radio"
                                        checked={state.aplicante_menor === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-aplicante_menor-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.aplicante_menor === 0 }
                                        onChange={e => handleChange(0,'aplicante_menor')}
                                        id="checked-aplicante_menor-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-aplicante_menor-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        state.aplicante_menor ?
                            <>
                                <div className={'mt-5 font-bold text-1xl'}>
                                    Dados dos pais / ou pessoa responável
                                </div>
                                <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                                    <Input
                                        required={true}
                                        large={true}
                                        className={'col-span-1'}
                                        label={'Nome'}
                                        name={'nome'}
                                        setValue={e => handleChange(e, 'nome')}
                                        value={state.nome}
                                    />
                                    <Input
                                        large={true}
                                        className={'col-span-1'}
                                        label={'Sobrenome'}
                                        name={'sobrenome'}
                                        setValue={e => handleChange(e, 'sobrenome')}
                                        value={state.sobrenome}
                                    />
                                    <Input
                                        large={true}
                                        className={'col-span-1'}
                                        label={'Endereço completo'}
                                        name={'endereco'}
                                        setValue={e => handleChange(e, 'endereco')}
                                        value={state.endereco}
                                    />
                                </div>
                                <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                                    <Input
                                        large={true}
                                        className={'col-span-1'}
                                        label={'CEP'}
                                        mask={'CEP'}
                                        name={'cep'}
                                        setValue={e => handleChange(e, 'cep')}
                                        value={state.cep}
                                    />
                                    <Input
                                        large={true}
                                        className={'col-span-1'}
                                        label={'Telefone'}
                                        mask={'phone'}
                                        name={'telefone'}
                                        setValue={e => handleChange(e, 'telefone')}
                                        value={state.telefone}
                                    />
                                    <Input
                                        large={true}
                                        className={'col-span-1'}
                                        label={'Celular'}
                                        mask={'cellphone'}
                                        name={'celular'}
                                        setValue={e => handleChange(e, 'celular')}
                                        value={state.celular}
                                    />
                                </div>
                                <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                                    <Input
                                        large={true}
                                        className={'col-span-1'}
                                        label={'Grau de parentesco'}
                                        name={'grau_parentesco'}
                                        setValue={e => handleChange(e, 'grau_parentesco')}
                                        value={state.grau_parentesco}
                                    />
                                    <Input
                                        large={true}
                                        className={'col-span-1'}
                                        label={'Número rg ou passaporte'}
                                        name={'passaporte_rg'}
                                        setValue={e => handleChange(e, 'passaporte_rg')}
                                        value={state.passaporte_rg}
                                    />

                                </div>

                                <div className={'grid md:grid-cols-3 gap-4 mt-[3rem]'}>
                                    <Input
                                        large={true}
                                        className={'col-span-1'}
                                        label={'2 - Nome'}
                                        name={'nome'}
                                        setValue={e => handleChange(e, 'nome')}
                                        value={state.segundo_nome}
                                    />
                                    <Input
                                        large={true}
                                        className={'col-span-1'}
                                        label={'2 - Sobrenome'}
                                        name={'sobrenome'}
                                        setValue={e => handleChange(e, 'sobrenome')}
                                        value={state.segundo_sobrenome}
                                    />
                                    <Input
                                        large={true}
                                        className={'col-span-1'}
                                        label={'2 - Endereço completo'}
                                        name={'endereco'}
                                        setValue={e => handleChange(e, 'endereco')}
                                        value={state.segundo_endereco}
                                    />
                                </div>
                                <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                                    <Input
                                        large={true}
                                        className={'col-span-1'}
                                        label={'2 - CEP'}
                                        mask={'CEP'}
                                        name={'segundo_cep'}
                                        setValue={e => handleChange(e, 'segundo_cep')}
                                        value={state.segundo_cep}
                                    />
                                    <Input
                                        large={true}
                                        className={'col-span-1'}
                                        label={'2 - Telefone'}
                                        mask={'phone'}
                                        name={'segundo_telefone'}
                                        setValue={e => handleChange(e, 'segundo_telefone')}
                                        value={state.segundo_telefone}
                                    />
                                    <Input
                                        large={true}
                                        className={'col-span-1'}
                                        label={'2 - Celular'}
                                        mask={'cellphone'}
                                        name={'segundo_celular'}
                                        setValue={e => handleChange(e, 'segundo_celular')}
                                        value={state.segundo_celular}
                                    />
                                </div>
                                <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                                    <Input
                                        large={true}
                                        className={'col-span-1'}
                                        label={'2 - Grau de parentesco'}
                                        name={'segundo_grau_parentesco'}
                                        setValue={e => handleChange(e, 'segundo_grau_parentesco')}
                                        value={state.segundo_grau_parentesco}
                                    />
                                    <Input
                                        large={true}
                                        className={'col-span-1'}
                                        label={'2 - Número rg ou passaporte'}
                                        name={'segundo_passaporte_rg'}
                                        setValue={e => handleChange(e, 'segundo_passaporte_rg')}
                                        value={state.segundo_passaporte_rg}
                                    />
                                </div>

                                <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                                    <div className={'flex flex-col md:col-span-1'}>
                                        <label
                                            className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Você pai ou guardião legal, irá viajar com o seu filho para a Austrália?
                                        </label>
                                        <div className={'flex  gap-4 mt-[1rem]'}>
                                            <div className={'flex'}>
                                                <input
                                                    onChange={e => handleChange(1,'guardiao_viaja')}
                                                    value={1}
                                                    id="checked-guardiao_viaja-s"
                                                    type="radio"
                                                    checked={state.guardiao_viaja === 1 }
                                                    className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                                <label
                                                    htmlFor="checked-guardiao_viaja-s"
                                                    className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                                >
                                                    Sim
                                                </label>
                                            </div>
                                            <div className={'flex'}>
                                                <input
                                                    checked={state.guardiao_viaja === 0 }
                                                    onChange={e => handleChange(0,'guardiao_viaja')}
                                                    id="checked-guardiao_viaja-n"
                                                    type="radio"
                                                    value={0}
                                                    className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                                <label
                                                    htmlFor="checked-guardiao_viaja-n"
                                                    className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                                >
                                                    Não
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                                    <div className={'flex flex-col md:col-span-1'}>
                                        <label
                                            className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Você pai ou guardião legal, autorizou o seu filho menor viajante, à morar com um parente pessoal (maior de 21 anos) na Austrália?
                                        </label>
                                        <div className={'flex  gap-4 mt-[1rem]'}>
                                            <div className={'flex'}>
                                                <input
                                                    onChange={e => handleChange(1,'guardiao_autoriza')}
                                                    value={1}
                                                    id="checked-guardiao_autoriza-s"
                                                    type="radio"
                                                    checked={state.guardiao_autoriza === 1 }
                                                    className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                                <label
                                                    htmlFor="checked-guardiao_autoriza-s"
                                                    className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                                >
                                                    Sim
                                                </label>
                                            </div>
                                            <div className={'flex'}>
                                                <input
                                                    checked={state.guardiao_autoriza === 0 }
                                                    onChange={e => handleChange(0,'guardiao_autoriza')}
                                                    id="checked-guardiao_autoriza-n"
                                                    type="radio"
                                                    value={0}
                                                    className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                                <label
                                                    htmlFor="checked-guardiao_autoriza-n"
                                                    className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                                >
                                                    Não
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                                    <div className={'flex flex-col md:col-span-1'}>
                                        <label
                                            className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Você pai ou guardião legal, tem ciência que o seu filho menor viajará desacompanhado por você(s), e nem ficará com um parente?
                                        </label>
                                        <div className={'flex  gap-4 mt-[1rem]'}>
                                            <div className={'flex'}>
                                                <input
                                                    onChange={e => handleChange(1,'guardiao_ciente')}
                                                    value={1}
                                                    id="checked-guardiao_ciente-s"
                                                    type="radio"
                                                    checked={state.guardiao_ciente === 1 }
                                                    className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                                <label
                                                    htmlFor="checked-guardiao_ciente-s"
                                                    className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                                >
                                                    Sim
                                                </label>
                                            </div>
                                            <div className={'flex'}>
                                                <input
                                                    checked={state.guardiao_ciente === 0 }
                                                    onChange={e => handleChange(0,'guardiao_ciente')}
                                                    id="checked-guardiao_ciente-n"
                                                    type="radio"
                                                    value={0}
                                                    className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                                <label
                                                    htmlFor="checked-guardiao_ciente-n"
                                                    className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                                >
                                                    Não
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </>
                            :
                            ''
                    }
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}