import Navbar from "../../../components/container/NavBar";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Alert from "../../../helpers/Alert";
import situacaoProcessoApi from "../../../services/situacaoProcessoApi";
import processosApi from "../../../services/processosApi";
import ProcessosResumo from "../../Processos/Resumo";
import ProcessosFichaCadastral from "../../Processos/FichaCadastral";
import ProcessosLinhaTempo from "../../Processos/LinhaTempo";
import Tabs from "../../../components/Tabs";
import cronogramaApi from "../../../services/cronogramaApi";

export default function ViewProcessoCliente() {
    const params = useParams();
    const id = params.id;

    const [stateProcesso, setStateProcesso] = useState<any>({});
    const [stateSituacao, setStateSituacao] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const [indexTabs, setIndexTabs] = useState(0)
    const [tabData, setTabData] = useState<any>([]);

    const [atualizar, setAtualizar] = useState(false);

    async function getData(){
        Alert.await(`Carregando dados...`);
        setLoading(true);
        if (id){
            const [situacaoProcesso, processo, cronogramaProcessoData] = await  Promise.all([situacaoProcessoApi.listAll(), processosApi.one(id), cronogramaApi.cronogramasProcesso(id)]);

            const cronogramaProcesso = cronogramaProcessoData.isError ? [] : cronogramaProcessoData.data.data;

            let arraySituacao: any[] = [];
            if (!situacaoProcesso.isError){
                // @ts-ignore
                arraySituacao = situacaoProcesso.data.data.map((situacao, index) => {
                    const cronogramaFind = cronogramaProcesso.find((cronograma: any) => cronograma.situacao_id === situacao.id);

                    if (cronogramaFind){
                        return {
                            id: situacao.id,
                            nome: situacao.nome,
                            data_finalizacao: cronogramaFind.data_prazo_finalizacao,
                            status: cronogramaFind.data_finalizacao ? 'concluido' : 'andamento', //andamento, concluido, aFazer
                        }
                    }else{
                        return {
                            id: situacao.id,
                            nome: situacao.nome,
                            status: 'aFazer', //andamento, concluido, aFazer
                        }
                    }
                });
                const indexs = arraySituacao.reduce(function(a: any, e: any, i: any) {
                    if (e.status === 'andamento'){
                        a.push(i);
                    }
                    return a;
                }, []);
                indexs.map((ind: any, key: number) => {
                    if (key){
                        arraySituacao[ind]['status'] = 'aFazer';

                    }
                })

            }
            let arrayProcessos = {};
            if (!processo.isError){
                setStateProcesso(processo.data.data);
                arrayProcessos = {
                    title: 'Resumo',
                    component: <ProcessosResumo
                        processoId={processo.data.data.id}
                        resumoData={processo.data.data}
                        setAtualizar={setAtualizar}
                    />
                };
            }

            const dataFinal = [
                arrayProcessos,
                {
                    title: 'Ficha Cadastral',
                    component: <ProcessosFichaCadastral processo={processo.data.data}/>
                },
                {
                    title: 'Linha do tempo',
                    component: <ProcessosLinhaTempo
                        cliente={true}
                        situacoes={arraySituacao}
                        processoId={processo.data.data.id}
                        setAtualizar={setAtualizar}
                        cronogramaAtual={processo.data.data.ultimo_cronograma ? processo.data.data.ultimo_cronograma : processo.data.data.ultimo_cronograma_concluido}
                        quantidadeProcessoResponsavel={processo.data.data.processos_membros_resumido}
                        cronogramaProcesso={cronogramaProcesso}
                    />
                },
            ]
            //console.log(dataFinal, dataFinal.reverse());
            setTabData(dataFinal.reverse());
        }
        /*if (id){
            const [situacaoProcesso, processo] = await  Promise.all([situacaoProcessoApi.listAll(), processosApi.one(id)]);

            let arraySituacao = [];
            if (!situacaoProcesso.isError){
                let em_andamento_encontrado = false;
                // @ts-ignore
                arraySituacao = situacaoProcesso.data.data.map((situacao, index) => {
                    if (processo.data.data.ultimo_cronograma_concluido){
                        if (processo.data.data.ultimo_cronograma_concluido.situacao_id === 6){
                            if (situacao.id === 6){
                                return {
                                    id: situacao.id,
                                    nome: situacao.nome,
                                    status: 'concluido', //andamento, concluido, aFazer
                                }
                            }else{
                                if (situacao.id === 7){
                                    return {
                                        id: situacao.id,
                                        nome: situacao.nome,
                                        status: 'aFazer', //andamento, concluido, aFazer
                                    }
                                }else{
                                    return {
                                        id: situacao.id,
                                        nome: situacao.nome,
                                        status: 'concluido', //andamento, concluido, aFazer
                                    }
                                }

                            }
                        }

                    }

                    if (processo.data.data.ultimo_cronograma){
                        if (processo.data.data.ultimo_cronograma.situacao_id === situacao.id){
                            em_andamento_encontrado = true;
                            return {
                                id: situacao.id,
                                nome: situacao.nome,
                                status: 'andamento', //andamento, concluido, aFazer
                            }
                        }else{
                            if (em_andamento_encontrado){
                                return {
                                    id: situacao.id,
                                    nome: situacao.nome,
                                    status: 'aFazer', //andamento, concluido, aFazer
                                }
                            }else{
                                return {
                                    id: situacao.id,
                                    nome: situacao.nome,
                                    status: 'concluido', //andamento, concluido, aFazer
                                }
                            }
                        }
                    }

                    if (em_andamento_encontrado){
                        return {
                            id: situacao.id,
                            nome: situacao.nome,
                            status: 'aFazer', //andamento, concluido, aFazer
                        }
                    }
                    em_andamento_encontrado = true;
                    return {
                        id: situacao.id,
                        nome: situacao.nome,
                        status: 'andamento', //andamento, concluido, aFazer
                    }
                })
            }
            let arrayProcessos = {};
            if (!processo.isError){
                setStateProcesso(processo.data.data);
                arrayProcessos = {
                    title: 'Resumo',
                    component: <ProcessosResumo
                        client={true}
                        processoId={processo.data.data.id}
                        resumoData={processo.data.data}
                        setAtualizar={setAtualizar}
                        onClickForm={() => setIndexTabs(indexTabs+1)}
                    />
                };
            }

            const dataFinal = [
                arrayProcessos,
                {
                    title: 'Ficha Cadastral',
                    component: <ProcessosFichaCadastral processo={processo.data.data} onClickForm={() => setIndexTabs(indexTabs-1)}/>
                },
                {
                    title: 'Linha do tempo',
                    component: <ProcessosLinhaTempo
                        cliente={true}
                        situacoes={arraySituacao}
                        processoId={processo.data.data.id}
                        //processoIdResponsavel={processo.data.data.id_processo_responsavel}
                        setAtualizar={setAtualizar}
                        cronogramaAtual={processo.data.data.ultimo_cronograma ? processo.data.data.ultimo_cronograma : processo.data.data.ultimo_cronograma_concluido}
                    />
                },
            ]
            setTabData(dataFinal);


        }*/
        setLoading(false);
        Alert.close();
    }

    useEffect(() => {
        setAtualizar(false);
        getData()
    }, [atualizar]);

    /*useEffect(() => {
        getData()
    }, [])*/
    if (loading) return (<></>);
    return(
        <div>
            <Navbar admin={false}/>
            <div className={'sm:px-[120px] sm:py-[1.8rem] '}>
                <div className={'text-[34px] text-gray-900  pb-4'}>
                    Processo
                </div>
                {
                    loading ? '' : <Tabs
                        tabData={tabData}
                        indexTabs={indexTabs}
                        setIndex={setIndexTabs}
                    />
                }
            </div>
        </div>
    )
}