import quantidade from "../../../../../helpers/QuantidadeFormularios";
import HeaderForm from "../../../../../components/headerForm";
import React, {useEffect, useState} from "react";
import Form from "../../../../../components/form/Form";
import passaporteApi from "../../../../../services/passaporteApi";
import InputSelect from "../../../../../components/input/InputSelect";
import Input from "../../../../../components/input/Input";
import Button from "../../../../../components/button/Button";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";

export interface IDadosConjugeEua{
    processo?: any;
    setTelaNumero?: any;
    telanumero?: any;
    setProcesso?: any;
}

export default function PassaporteEua({processo, setProcesso, setTelaNumero, telanumero}: IDadosConjugeEua) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        tipo_passaporte: 'comum',
        tipo_passaporte_outro: '',
        numero: '',
        pais_emissor: '',
        data_emissao: '',
        data_validade: '',
        orgao_emissor: '',
        cidade_emissao: '',
        passaporte_roubado: '0',
    });
    const [numeroTelaAnterior, setNumeroTelaAnterior] = useState(telanumero-2)

    const [loading, setLoading] = useState(false);

    const tipoPassaporte = [
        { value: 'comum', label: 'Passaporte Comum/Regular' },
        { value: 'diplomatico', label: 'Passaporte Diplomático' },
        { value: 'servico', label: 'Passaporte de Serviço' },
        { value: 'outro', label: 'Outro' },
    ];

    useEffect(() => {
        setLoading(true)
        let numAnt = 1;
        numAnt = processo.cliente.estado_civil !== 'solteiro' ? (telanumero-1) : 1;
        setNumeroTelaAnterior(numAnt);
        getDadosPassaporte();

    }, []);


    const getDadosPassaporte = async () => {
        const {data, isError} = await passaporteApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }
        setLoading(false);
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await passaporteApi.update(state.id, state) : await passaporteApi.create(state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, 'percet_conclusao': percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        let numero = telanumero + 1;
        setTelaNumero(numero);
    }
    return(
        <>
            <HeaderForm
                titulo={'Dados do passaporte'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Tipo"}
                                selected={tipoPassaporte.filter((opt: any) => opt.value === state.tipo_passaporte)}
                                options={tipoPassaporte}
                                onChangeSelect={(e: any) => handleChange(e.value, 'tipo_passaporte')}
                            />

                        </div>
                        {
                            state.tipo_passaporte === 'outro' ?
                                <Input
                                    className={'col-span-2'}
                                    label={'Outro qual?'}
                                    placeholder={'Outro qual?'}
                                    large={true}
                                    name={'tipo_passaporte_outro'}
                                    setValue={e => handleChange(e,'tipo_passaporte_outro')}
                                    value={state.tipo_passaporte_outro}
                                />
                                :
                                ''
                        }
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Número do passaporte válido '}
                            placeholder={'Número do passaporte válido'}
                            large={true}
                            name={'numero'}
                            setValue={e => handleChange(e,'numero')}
                            value={state.numero}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'País que emitiu o passaporte '}
                            placeholder={'País que emitiu o passaporte'}
                            large={true}
                            name={'pais_emissor'}
                            setValue={e => handleChange(e,'pais_emissor')}
                            value={state.pais_emissor}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Data de emissão '}
                            placeholder={'Data de emissão'}
                            large={true}
                            type={'date'}
                            name={'data_emissao'}
                            setValue={e => handleChange(e,'data_emissao')}
                            value={state.data_emissao}
                            required
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Valido até '}
                            placeholder={'Valido até'}
                            large={true}
                            type={'date'}
                            name={'data_validade'}
                            setValue={e => handleChange(e,'data_validade')}
                            value={state.data_validade}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Autoridade '}
                            placeholder={'Autoridade'}
                            large={true}
                            name={'orgao_emissor'}
                            setValue={e => handleChange(e,'orgao_emissor')}
                            value={state.orgao_emissor}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Cidade onde foi emitido '}
                            placeholder={'Cidade onde foi emitido'}
                            large={true}
                            name={'cidade_emissao'}
                            setValue={e => handleChange(e,'cidade_emissao')}
                            value={state.cidade_emissao}
                            required
                        />
                    </div>
                    <div className={'flex flex-col my-[24px]'}>
                        <label
                            className="text-[16px] font-medium  "
                        >
                            Já teve algum passaporte roubado?
                        </label>
                        <div className={'flex  gap-4'}>
                            <div className={'flex'}>
                                <input
                                    onChange={e => handleChange(1,'passaporte_roubado')}
                                    value={1}
                                    id="checked-passaporte_roubado-s"
                                    type="radio"
                                    checked={state.passaporte_roubado === '1' }
                                    className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                    htmlFor="checked-passaporte_roubado-s"
                                    className="ms-2 text-[16px] font-medium  "
                                >
                                    Sim
                                </label>
                            </div>
                            <div className={'flex'}>
                                <input
                                    checked={state.passaporte_roubado === '0' }
                                    onChange={e => handleChange(0,'passaporte_roubado')}
                                    id="checked-passaporte_roubado-n"
                                    type="radio"
                                    value={0}
                                    className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                    htmlFor="checked-passaporte_roubado-n"
                                    className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Não
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(numeroTelaAnterior)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}