import React, {useEffect, useState} from "react";
import informacoesEducacionaisApi from "../../../../../services/informacoesEducacionaisApi";
import Alert from "../../../../../helpers/Alert";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import processosApi from "../../../../../services/processosApi";
import HeaderForm from "../../../../../components/headerForm";
import Form from "../../../../../components/form/Form";
import Input from "../../../../../components/input/Input";
import Button from "../../../../../components/button/Button";

export interface IDadosViageEducacionalAusEstudante{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DadosViageEducacionalAusEstudante({processo, setProcesso, setTelaNumero, telanumero}: IDadosViageEducacionalAusEstudante) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        tipo_intercambio: '',

        duracao_viagem: '',
        dependente_familiar: 0,
        informacoes_dependente_familiar: '',
        historico_escolar: '',
        grau_formacao: '',
        nome_instituicao: '',
        nome_curso: '',
        endereco: '',
        data_inicio: '',
        data_fim: '',

        segundo_grau_formacao: '',
        segundo_nome_instituicao: '',
        segundo_endereco: '',
        segundo_data_inicio: '',
        segundo_data_fim: '',

        terceiro_grau_formacao: '',
        terceiro_nome_instituicao: '',
        terceiro_endereco: '',
        terceiro_data_inicio: '',
        terceiro_data_fim: '',

        estudou_pais: 0,

        primeiro_curso_realizado: '',
        primeiro_nome_intituicao_curso: '',
        primeiro_data_inicio_curso: '',
        primeiro_data_fim_curso: '',

        segundo_curso_realizado: '',
        segundo_nome_intituicao_curso: '',
        segundo_data_inicio_curso: '',
        segundo_data_fim_curso: '',

        curso_pretende: '',
        curso_pretende_intituicao: '',
        curso_pretende_inicio: '',
        curso_pretende_fim: '',

        recebeu_ecoe: '',
        motivos_centrais: '',
        contato_brasil_nome: '',
        contato_brasil_parentesco: '',
        contato_brasil_endereco: '',
        contato_brasil_telefone: '',
        informacoes_educacionais: 1,
    })

    const getData = async () => {
        const {data, isError} = await informacoesEducacionaisApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                const response = data.data;
                setState(response);
            }
        }
    }

    useEffect(() => {
        getData()
    }, []);
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await informacoesEducacionaisApi.update(state.id, state) : await informacoesEducacionaisApi.create(state);
        if (isError){

            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    return(
        <>
            <HeaderForm
                titulo={'Dados da viagem e educacionais'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            required={true}
                            large={true}
                            label={'Que tipo de intercâmbio irá realizar?'}
                            name={'tipo_intercambio'}
                            setValue={e => handleChange(e,'tipo_intercambio')}
                            value={state.tipo_intercambio}
                        />
                        <Input
                            className={'md:col-span-1'}
                            required={true}
                            large={true}
                            label={'Duração da sua viagem?'}
                            name={'duracao_viagem'}
                            setValue={e => handleChange(e,'duracao_viagem')}
                            value={state.duracao_viagem}
                        />
                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Você levará algum dependente familiar na sua viagem e no seu visto?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={e => handleChange(1,'dependente_familiar')}
                                            value={1}
                                            id="checked-dependente_familiar-s"
                                            type="radio"
                                            checked={state.dependente_familiar === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-dependente_familiar-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.dependente_familiar === 0 }
                                            onChange={e => handleChange(0,'dependente_familiar')}
                                            id="checked-dependente_familiar-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-dependente_familiar-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Input
                            className={'md:col-span-1'}
                            required={true}
                            large={true}
                            label={'Em caso afirmativo, por favor informar o nome completo e parentesco.'}
                            name={'informacoes_dependente_familiar'}
                            setValue={e => handleChange(e,'informacoes_dependente_familiar')}
                            value={state.informacoes_dependente_familiar}
                        />
                    </div>
                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            required={true}
                            large={true}
                            label={'Favor informar o seu histórico escolar atual, tal como faculdade ou curso de especialização: (Fora da Austrália)'}
                            name={'historico_escolar'}
                            setValue={e => handleChange(e,'historico_escolar')}
                            value={state.historico_escolar}
                        />
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'text-[20px]'}>1º formação</div>
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Grau de formação'}
                            name={'grau_formacao'}
                            setValue={e => handleChange(e,'grau_formacao')}
                            value={state.grau_formacao}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Nome da instituição'}
                            name={'nome_instituicao'}
                            setValue={e => handleChange(e,'nome_instituicao')}
                            value={state.nome_instituicao}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Curso / Área de formação'}
                            name={'nome_curso'}
                            setValue={e => handleChange(e,'nome_curso')}
                            value={state.nome_curso}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Endereço completo, com o CEP'}
                            name={'endereco'}
                            setValue={e => handleChange(e,'endereco')}
                            value={state.endereco}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            type={'date'}
                            label={'Data de início do curso'}
                            name={'data_inicio'}
                            setValue={e => handleChange(e,'data_inicio')}
                            value={state.data_inicio}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            type={'date'}
                            label={'Data de termino do curso'}
                            name={'data_fim'}
                            setValue={e => handleChange(e,'data_fim')}
                            value={state.data_fim}
                        />
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'text-[20px]'}>2º formação</div>
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Grau de formação'}
                            name={'segundo_grau_formacao'}
                            setValue={e => handleChange(e,'segundo_grau_formacao')}
                            value={state.segundo_grau_formacao}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Nome da instituição'}
                            name={'segundo_nome_instituicao'}
                            setValue={e => handleChange(e,'segundo_nome_instituicao')}
                            value={state.segundo_nome_instituicao}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            type={'date'}
                            label={'Data de início do curso'}
                            name={'segundo_data_inicio'}
                            setValue={e => handleChange(e,'segundo_data_inicio')}
                            value={state.segundo_data_inicio}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            type={'date'}
                            label={'Data de termino do curso'}
                            name={'segundo_data_fim'}
                            setValue={e => handleChange(e,'segundo_data_fim')}
                            value={state.segundo_data_fim}
                        />
                        <Input
                            className={'md:col-span-2'}
                            large={true}
                            label={'Endereço completo, com o CEP'}
                            name={'segundo_endereco'}
                            setValue={e => handleChange(e,'segundo_endereco')}
                            value={state.segundo_endereco}
                        />
                    </div>


                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'text-[20px]'}>3º formação</div>
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Grau de formação'}
                            name={'terceiro_grau_formacao'}
                            setValue={e => handleChange(e,'terceiro_grau_formacao')}
                            value={state.terceiro_grau_formacao}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Nome da instituição'}
                            name={'terceiro_nome_instituicao'}
                            setValue={e => handleChange(e,'terceiro_nome_instituicao')}
                            value={state.terceiro_nome_instituicao}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            type={'date'}
                            label={'Data de início do curso'}
                            name={'terceiro_data_inicio'}
                            setValue={e => handleChange(e,'terceiro_data_inicio')}
                            value={state.terceiro_data_inicio}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            type={'date'}
                            label={'Data de termino do curso'}
                            name={'terceiro_data_fim'}
                            setValue={e => handleChange(e,'terceiro_data_fim')}
                            value={state.terceiro_data_fim}
                        />
                        <Input
                            className={'md:col-span-2'}
                            large={true}
                            label={'Endereço completo, com o CEP'}
                            name={'terceiro_endereco'}
                            setValue={e => handleChange(e,'terceiro_endereco')}
                            value={state.terceiro_endereco}
                        />
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Você já estudou na Austrália?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={e => handleChange(1,'estudou_pais')}
                                            value={1}
                                            id="checked-estudou_pais-s"
                                            type="radio"
                                            checked={state.estudou_pais === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-estudou_pais-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.estudou_pais === 0 }
                                            onChange={e => handleChange(0,'estudou_pais')}
                                            id="checked-estudou_pais-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-estudou_pais-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'text-[20px]'}>1º Curso</div>
                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Caso tenha respondido "Sim", favor informar qual o curso realizado'}
                            name={'primeiro_curso_realizado'}
                            setValue={e => handleChange(e,'primeiro_curso_realizado')}
                            value={state.primeiro_curso_realizado}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Nome da instituição'}
                            name={'primeiro_nome_intituicao_curso'}
                            setValue={e => handleChange(e,'primeiro_nome_intituicao_curso')}
                            value={state.primeiro_nome_intituicao_curso}
                        />

                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            type={'date'}
                            label={'Data de início do curso'}
                            name={'primeiro_data_inicio_curso'}
                            setValue={e => handleChange(e,'primeiro_data_inicio_curso')}
                            value={state.primeiro_data_inicio_curso}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            type={'date'}
                            label={'Data de termino do curso'}
                            name={'primeiro_data_fim_curso'}
                            setValue={e => handleChange(e,'primeiro_data_fim_curso')}
                            value={state.primeiro_data_fim_curso}
                        />
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'text-[20px]'}>2º Curso</div>
                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Caso tenha respondido "Sim", favor informar qual o curso realizado'}
                            name={'segundo_curso_realizado'}
                            setValue={e => handleChange(e,'segundo_curso_realizado')}
                            value={state.segundo_curso_realizado}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Nome da instituição'}
                            name={'segundo_nome_intituicao_curso'}
                            setValue={e => handleChange(e,'segundo_nome_intituicao_curso')}
                            value={state.segundo_nome_intituicao_curso}
                        />

                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            type={'date'}
                            label={'Data de início do curso'}
                            name={'segundo_data_inicio_curso'}
                            setValue={e => handleChange(e,'segundo_data_inicio_curso')}
                            value={state.segundo_data_inicio_curso}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            type={'date'}
                            label={'Data de termino do curso'}
                            name={'segundo_data_fim_curso'}
                            setValue={e => handleChange(e,'segundo_data_fim_curso')}
                            value={state.segundo_data_fim_curso}
                        />
                    </div>


                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Qual curso você pretende fazer enquanto estiver na Austrália?'}
                            name={'curso_pretende'}
                            setValue={e => handleChange(e,'curso_pretende')}
                            value={state.curso_pretende}
                        />

                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Nome da instituição'}
                            name={'curso_pretende_intituicao'}
                            setValue={e => handleChange(e,'curso_pretende_intituicao')}
                            value={state.curso_pretende_intituicao}
                        />
                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-[3rem]'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            type={'date'}
                            label={'Início do curso'}
                            name={'curso_pretende_inicio'}
                            setValue={e => handleChange(e,'curso_pretende_inicio')}
                            value={state.curso_pretende_inicio}
                        />

                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            type={'date'}
                            label={'Termino do curso'}
                            name={'curso_pretende_fim'}
                            setValue={e => handleChange(e,'curso_pretende_fim')}
                            value={state.curso_pretende_fim}
                        />
                    </div>

                    <div className={'grid md:grid-cols-5 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1 mt-[1.5rem]'}
                            large={true}
                            label={'Você já recebeu o eCOE'}
                            name={'recebeu_ecoe'}
                            setValue={e => handleChange(e,'recebeu_ecoe')}
                            value={state.recebeu_ecoe}
                        />

                        <Input
                            className={'md:col-span-4'}
                            large={true}
                            label={'Informe os motivos centrais de ter escolhido a Austrália como destino para a viagem (em inglês para que não seja necessária tradução e com, no máximo 2000 caracteres)'}
                            name={'motivos_centrais'}
                            setValue={e => handleChange(e,'motivos_centrais')}
                            value={state.motivos_centrais}
                        />
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Nome do contato emergencial no Brasil'}
                            name={'contato_brasil_nome'}
                            setValue={e => handleChange(e,'contato_brasil_nome')}
                            value={state.contato_brasil_nome}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Qual o parentesco/relação com você'}
                            name={'contato_brasil_parentesco'}
                            setValue={e => handleChange(e,'contato_brasil_parentesco')}
                            value={state.contato_brasil_parentesco}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Endereço completo do contato'}
                            name={'contato_brasil_endereco'}
                            setValue={e => handleChange(e,'contato_brasil_endereco')}
                            value={state.contato_brasil_endereco}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            mask={'cellphone'}
                            label={'Telefone do contato emergencia'}
                            name={'contato_brasil_telefone'}
                            setValue={e => handleChange(e,'contato_brasil_telefone')}
                            value={state.contato_brasil_telefone}
                        />
                    </div>

                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}