import {useParams} from "react-router-dom";
import PageContainer from "../../../components/container/PageContainer";
import React, {useEffect, useState} from "react";
import Tabs from "../../../components/Tabs";
import ProcessosResumo from "../Resumo";
import ProcessosFichaCadastral from "../FichaCadastral";
import ProcessosLinhaTempo from "../LinhaTempo";
import Alert from "../../../helpers/Alert";
import processosApi from "../../../services/processosApi";
import situacaoProcessoApi from "../../../services/situacaoProcessoApi";
import useModal from '../../../hooks/useModal';
import cronogramaApi from "../../../services/cronogramaApi";

export default function ViewProcessos() {
    const params = useParams();
    const id = params.id;

    const [stateProcesso, setStateProcesso] = useState<any>({});
    const [stateSituacao, setStateSituacao] = useState<any>({});
    const [loading, setLoading] = useState(true);

    const [tabData, setTabData] = useState<any>([]);

    const [atualizar, setAtualizar] = useState(false);

    async function getData(){
        Alert.await(`Carregando dados...`);
        setLoading(true);
        if (id){
            const [situacaoProcesso, processo, cronogramaProcessoData] = await  Promise.all([situacaoProcessoApi.listAll(), processosApi.one(id), cronogramaApi.cronogramasProcesso(id)]);

            const cronogramaProcesso = cronogramaProcessoData.isError ? [] : cronogramaProcessoData.data.data;

            let arraySituacao: any[] = [];
            if (!situacaoProcesso.isError){
                // @ts-ignore
                arraySituacao = situacaoProcesso.data.data.map((situacao, index) => {
                    const cronogramaFind = cronogramaProcesso.find((cronograma: any) => cronograma.situacao_id === situacao.id);

                    if (cronogramaFind){
                        return {
                            id: situacao.id,
                            nome: situacao.nome,
                            data_finalizacao: cronogramaFind.data_prazo_finalizacao,
                            status: cronogramaFind.data_finalizacao ? 'concluido' : 'andamento', //andamento, concluido, aFazer
                        }
                    }else{
                        return {
                            id: situacao.id,
                            nome: situacao.nome,
                            status: 'aFazer', //andamento, concluido, aFazer
                        }
                    }
                });
                const indexs = arraySituacao.reduce(function(a: any, e: any, i: any) {
                    if (e.status === 'andamento'){
                        a.push(i);
                    }
                    return a;
                }, []);
                indexs.map((ind: any, key: number) => {
                    if (key){
                        arraySituacao[ind]['status'] = 'aFazer';

                    }
                })

            }
            let arrayProcessos = {};
            if (!processo.isError){
                setStateProcesso(processo.data.data);
                arrayProcessos = {
                    title: 'Resumo',
                    component: <ProcessosResumo
                        processoId={processo.data.data.id}
                        resumoData={processo.data.data}
                        setAtualizar={setAtualizar}
                    />
                };
            }

            const dataFinal = [
                arrayProcessos,
                {
                    title: 'Ficha Cadastral',
                    component: <ProcessosFichaCadastral processo={processo.data.data}/>
                },
                {
                    title: 'Linha do tempo',
                    component: <ProcessosLinhaTempo
                        situacoes={arraySituacao}
                        processoId={processo.data.data.id}
                        setAtualizar={setAtualizar}
                        cronogramaAtual={processo.data.data.ultimo_cronograma ? processo.data.data.ultimo_cronograma : processo.data.data.ultimo_cronograma_concluido}
                        quantidadeProcessoResponsavel={processo.data.data.processos_membros_resumido}
                        cronogramaProcesso={cronogramaProcesso}
                    />
                },
            ]
            //console.log(dataFinal, dataFinal.reverse());
            setTabData(dataFinal.reverse());
        }
        setLoading(false);
        Alert.close();
    }



    useEffect(() => {
        setAtualizar(false);
        getData()
    }, [atualizar]);

    useEffect(() => {
        getData()
    }, [])
    if (loading) return (<></>);

    return(
        <>
            <PageContainer pageTitle={'Processos'} pageTitleSecond={`${stateProcesso.cliente?.nome} ${stateProcesso.cliente?.sobrenome}`}>
                {
                    loading ? '' : <Tabs tabData={tabData}/>
                }
            </PageContainer>

        </>
    )
}