import quantidade from "../../../../../helpers/QuantidadeFormularios";
import HeaderForm from "../../../../../components/headerForm";
import React, {useEffect, useState} from "react";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import perguntaSegurancaApi from "../../../../../services/perguntaSegurancaApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import InputSelect from "../../../../../components/input/InputSelect";

export interface IConsulado{
    processo?: any;
    setTelaNumero?: any;
    telanumero?: any;
    setProcesso?: any;
}
export default function Consulado({processo, setTelaNumero, telanumero, setProcesso}: IConsulado) {
    const [state, setState] = useState({
        processo_id: processo.id,
        consulado: processo.consulado ? processo.consulado : 'casv_brasilia'
    });
    const [atualizacao, setAtualizacao] = useState(false);

    const consuladoOptions = [
        { value: 'brasilia', label: 'Embaixada de Brasília - SES Avenida das Nações, Quadra 801, Lote 03 - Asa Sul' },
        { value: 'rio_janeiro', label: 'Consulado do Rio de Janeiro: Avenida Presidente Wilson, 147 – Castelo (entrada na Rua Santa Luzia)' },
        { value: 'recife', label: 'Consulado de Recife: Rua Gonçalves Maia, 163 – Bairro Boa Vista' },
        { value: 'sao_paulo', label: 'Consulado de São Paulo: Rua Henri Dunant, 500 – Chácara Santo Antônio' },
        { value: 'porto_alegre', label: 'Consulado de Porto Alegre - Avenida Assis Brasil, 1889 – Passo D’Areia Porto Alegre – RS 91010-004' },
        { value: 'isento', label: 'Estou isento(a) desta etapa por meu visto B1/B2 anterior ter sido emitido a partir de 2004' },
    ]
    async function onSubmit() {
        const {data, isError} = await processosApi.update(state.processo_id, state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!atualizacao){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero+1);
    }
    const getDataProcesso = async () => {
        const {data, isError} = await processosApi.one(processo.id);
        if (!isError) {
            if (Object.values(data.data).length) {
                setProcesso(data.data);
                setState({
                    processo_id: processo.id,
                    consulado: data.data.consulado ? data.data.consulado : 'brasilia',
                })
            }
        }
    }
    useEffect(() => {
        getDataProcesso()
    }, [])
    return(
        <>
            <HeaderForm
                titulo={'Consulado de sua preferência'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>

                        <div className={'col-span-2'}>
                            <InputSelect
                                label={"Consulado de sua preferência"}
                                selected={consuladoOptions.filter((opt: any) => opt.value === state.consulado)}
                                options={consuladoOptions}
                                onChangeSelect={(e: any) => setState({...state, ['consulado']: e.value})}
                            />
                            <div className={'text-gray-400 text-sm'}>
                                Escolha o Consulado onde fará sua entrevista. Crianças até 15 anos, idosos acima de 65 anos e detentores de um visto emitido a partir de 2004 estão isentos desta etapa.
                            </div>
                        </div>

                    </div>
                </div>

                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}