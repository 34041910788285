import quantidade from "../../../../../helpers/QuantidadeFormularios";
import HeaderForm from "../../../../../components/headerForm";
import React, {useEffect, useState} from "react";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import InputSelect from "../../../../../components/input/InputSelect";

export interface IDeclaracao{
    processo?: any;
    setTelaNumero?: any;
    telanumero?: any;
    setProcesso?: any;
}
export default function Declaracao({processo, setTelaNumero, telanumero, setProcesso}: IDeclaracao) {

    const [state, setState] = useState(false);
    const [telaRetorno, setTelaRetorno] = useState(telanumero-1)

    async function onSubmit() {
        const confirm = await Alert.confirm('Deseja realmente concluir esse processo?', 'Concluir processo');
        if (!confirm) return;
        Alert.await("Salvando dados");
        const finalData = {
            declaracao: state,
            conclusao_formulario: true,
            percet_conclusao: 100
        }
        const {data, isError} = await processosApi.update(processo.id, finalData);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        setProcesso({...processo, ['percet_conclusao']: 100.00 })
        Alert.success('Formulario concluído com sucesso');
        window.scrollTo(0, 0);
        setTelaNumero(telanumero+1);
    }

    useEffect(() => {
        const arr = processo.local_colheita_biometria.split("_");
        let numero = telanumero;
        if (arr[0] === 'casv'){
            numero = telanumero - 1
        }else{
            numero = telanumero - 3
        }
        setTelaRetorno(numero);
    }, [])
    return(
        <>
            <HeaderForm
                titulo={'Declaração'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>

                        <div className={'col-span-2 text-2xl font-semibold'}>
                            Declaração: Todas as respostas deste formulário devem ser conferidas, pois serão utilizadas no formulário eletrônico da Imigração. Qualquer informação errada repassada através deste formulário é de responsabilidade exclusiva do requerente.
                        </div>

                    </div>
                    <div>
                        <div className={'flex mt-4'}>
                            <input
                                onChange={() => setState(!state)}
                                checked={state}
                                id="checked-checkbox-2"
                                type="checkbox"
                                value=""
                                className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                                htmlFor="checked-checkbox-2"
                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                <div className={'flex flex-col'}>
                                    Li e concordo com os termos mencionados acima
                                </div>

                            </label>
                        </div>
                    </div>
                </div>

                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>

                    <Button
                        onClick={() => setTelaNumero(telaRetorno)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Concluir
                    </Button>
                </div>
            </Form>
        </>
    )
}