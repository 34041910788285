import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import React, {useEffect, useState} from "react";
import Button from "../../../../../components/button/Button";
import paisApi from "../../../../../services/paisApi";
import parceirosApi from "../../../../../services/parceirosApi";
import permissaoEstudosApi from "../../../../../services/permissaoEstudosApi";
import Alert from "../../../../../helpers/Alert";
import clientesApi from "../../../../../services/clientesApi";
import processosApi from "../../../../../services/processosApi";
import Input from "../../../../../components/input/Input";
import InputSelect from "../../../../../components/input/InputSelect";
import InputCurrency from "../../../../../components/inputCurrency";

export interface IPermissaoEstudosCa{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function PermissaoEstudosCa({processo, setProcesso, setTelaNumero, telanumero}: IPermissaoEstudosCa) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        nome_instituicao: '',
        provincia: 'AB - Alberta',
        cidade: '',
        endereco: '',
        campo_estudo: '',
        campo_estudo_outro: '',
        data_entrada: '',
        data_saida: '',
        codigo_dli: '',
        student_id: '',
        valor_curso: '',
        valor_acomodacao: '',
        valor_fundo_disponivel: '1000',
        quem_custeara: 'Eu mesmo',
    });
    const provinciaOptions = [
        { value: 'AB - Alberta', label: 'AB - Alberta' },
        { value: 'BC - British Columbia', label: 'BC - British Columbia' },
        { value: 'MB - Manitoba', label: 'MB - Manitoba' },
        { value: 'NB - New Brunswick', label: 'NB - New Brunswick' },
        { value: 'NL - Newfoundland and Labrador', label: 'NL - Newfoundland and Labrador' },
        { value: 'NS - Nova Scotia', label: 'NS - Nova Scotia' },
        { value: 'NT - Northwest Territories', label: 'NT - Northwest Territories' },
        { value: 'NU - Nunavut', label: 'NU - Nunavut' },
        { value: 'ON - Ontario', label: 'ON - Ontario' },
        { value: 'PE - Prince Edward Island', label: 'PE - Prince Edward Island' },
        { value: 'QC - Québec', label: 'QC - Québec' },
        { value: 'SK - Saskatchewan', label: 'SK - Saskatchewan' },
        { value: 'YT - Yukon Territories', label: 'YT - Yukon Territories' },
    ];

    const campoEstudoOptions = [
        { value: 'Artes / Humanidades / Ciências Sociais', label: 'Artes / Humanidades / Ciências Sociais' },
        { value: 'Artes, Belas artes / Visual / Performing', label: 'Artes, Belas artes / Visual / Performing' },
        { value: 'Informática / TI', label: 'Informática / TI' },
        { value: 'ESL / FSL - cursos de idiomas', label: 'ESL / FSL - cursos de idiomas' },
        { value: 'Treinamento de vôo', label: 'Treinamento de vôo' },
        { value: 'Hospitalidade / Turismo', label: 'Hospitalidade / Turismo' },
        { value: 'Lei/Direito', label: 'Lei/Direito' },
        { value: 'Medicina', label: 'Medicina' },
        { value: 'Ciências, Geral', label: 'Ciências, Geral' },
        { value: 'Comércio / Formação profissional', label: 'Comércio / Formação profissional' },
        { value: 'Teologia / Estudos Religiosos', label: 'Teologia / Estudos Religiosos' },
        { value: 'Agricultura / Agric Ops / Rel Sciences', label: 'Agricultura / Agric Ops / Rel Sciences' },
        { value: 'Ciências Biológicas / Biomédicas', label: 'Ciências Biológicas / Biomédicas' },
        { value: 'Negócios / Mgmt / Marketing', label: 'Negócios / Mgmt / Marketing' },
        { value: 'Outro', label: 'Outro' },
    ];

    const quemCustearaOptions = [
        { value: 'Eu mesmo', label: 'Eu mesmo' },
        { value: 'Pai', label: 'Pai' },
        { value: 'Mãe', label: 'Mãe' },
        { value: 'Ambos (Pai e Mãe)', label: 'Ambos (Pai e Mãe)' },
        { value: 'Outro', label: 'Outro' },
    ]

    const getData = async () => {
        const {data, isError} = await permissaoEstudosApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }

    }

    useEffect(() => {
        getData()
    }, []);
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await permissaoEstudosApi.update(state.id, state) : await permissaoEstudosApi.create(state);
        if (isError){

            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    return(
        <>
            <HeaderForm
                titulo={processo.tipo_visto_id === 3 ? "Permissão de estudos" : "Visto de residente temporário"}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    {
                        processo.tipo_visto_id === 3 ?
                            <>
                                <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                                    <Input
                                        className={'md:col-span-1'}
                                        large={true}
                                        label={'Nome da Instituição de Ensino'}
                                        required={true}
                                        name={'nome_instituicao'}
                                        setValue={e => handleChange(e,'nome_instituicao')}
                                        value={state.nome_instituicao}
                                    />
                                    <div className={'col-span-1'}>
                                        <InputSelect
                                            label={"Provincia"}
                                            selected={provinciaOptions.filter((opt: any) => opt.value === state.provincia)}
                                            options={provinciaOptions}
                                            onChangeSelect={(e: any) => handleChange(e.value, 'provincia')}
                                        />
                                    </div>
                                    <Input
                                        className={'md:col-span-1'}
                                        large={true}
                                        label={'Cidade'}
                                        required={true}
                                        name={'cidade'}
                                        setValue={e => handleChange(e,'cidade')}
                                        value={state.cidade}
                                    />
                                </div>
                                <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                                    <Input
                                        className={'md:col-span-4'}
                                        large={true}
                                        label={'Endereço da instituição'}
                                        required={true}
                                        name={'endereco'}
                                        setValue={e => handleChange(e,'endereco')}
                                        value={state.endereco}
                                        legenda={'Incluir bairro e CEP.'}
                                    />
                                    {/*<div className={'col-span-1'}>
                                        <InputSelect
                                            label={"Campo de estudo"}
                                            selected={campoEstudoOptions.filter((opt: any) => opt.value === state.campo_estudo)}
                                            options={campoEstudoOptions}
                                            onChangeSelect={(e: any) => handleChange(e.value, 'campo_estudo')}
                                        />
                                    </div>
                                    <Input
                                        className={'md:col-span-1'}
                                        large={true}
                                        label={'Outro'}
                                        name={'campo_estudo_outro'}
                                        setValue={e => handleChange(e,'campo_estudo_outro')}
                                        value={state.campo_estudo_outro}
                                    />*/}
                                </div>
                                <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                                    <Input
                                        className={'md:col-span-1'}
                                        large={true}
                                        type={'date'}
                                        label={'Data da entrada'}
                                        required={true}
                                        name={'data_entrada'}
                                        setValue={e => handleChange(e,'data_entrada')}
                                        value={state.data_entrada}
                                    />
                                    <Input
                                        className={'md:col-span-1'}
                                        large={true}
                                        type={'date'}
                                        label={'Data da saida'}
                                        required={true}
                                        name={'data_saida'}
                                        setValue={e => handleChange(e,'data_saida')}
                                        value={state.data_saida}
                                    />
                                    <Input
                                        className={'md:col-span-2'}
                                        large={true}
                                        label={'Código DLI (Designated Learning Institution DLI)'}
                                        name={'codigo_dli'}
                                        setValue={e => handleChange(e,'codigo_dli')}
                                        value={state.codigo_dli}
                                    />
                                </div>
                                <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                                    <Input
                                        className={'md:col-span-2'}
                                        large={true}
                                        label={'Student ID'}
                                        required={true}
                                        name={'student_id'}
                                        setValue={e => handleChange(e,'student_id')}
                                        value={state.student_id}
                                    />
                                    <Input
                                        className={'md:col-span-1'}
                                        large={true}
                                        label={'Valor total do curso'}
                                        required={true}
                                        name={'valor_curso'}
                                        setValue={e => handleChange(e,'valor_curso')}
                                        value={state.valor_curso}
                                    />
                                    <Input
                                        className={'md:col-span-1'}
                                        large={true}
                                        label={'Valor da acomodação'}
                                        required={true}
                                        name={'valor_acomodacao'}
                                        setValue={e => handleChange(e,'valor_acomodacao')}
                                        value={state.valor_acomodacao}
                                    />
                                </div>
                                <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                                    {/*<Input
                                        className={'md:col-span-1'}
                                        large={true}
                                        label={'Fundos disponíveis para a sua visita (em dólares canadenses)'}
                                        required={true}
                                        name={'valor_fundo_disponivel'}
                                        setValue={e => handleChange(e,'valor_fundo_disponivel')}
                                        value={state.valor_fundo_disponivel}
                                        legenda={'O Consulado utiliza um cálculo em sua avaliação de que é recomendado que todos os visitantes tenham, pelo menos 800 dólares canadenses por mês de viagem.'}
                                    />*/}
                                    <InputCurrency
                                        moeda={'eua'}
                                        label={'Fundos disponíveis para a sua visita (em dólares canadenses)'}
                                        className={'col-span-1'}
                                        required={true}
                                        value={state.valor_fundo_disponivel}
                                        onChange={(e: any) => handleChange(e,'valor_fundo_disponivel')}
                                        decimalSeparator=","
                                        decimalScale={2}
                                        legenda={'O Consulado utiliza um cálculo em sua avaliação de que é recomendado que todos os visitantes tenham, pelo menos 800 dólares canadenses por mês de viagem.'}
                                    />
                                    <div className={'col-span-1'}>
                                        <InputSelect
                                            label={"Quem custeará sua viagem"}
                                            selected={quemCustearaOptions.filter((opt: any) => opt.value === state.quem_custeara)}
                                            options={quemCustearaOptions}
                                            onChangeSelect={(e: any) => handleChange(e.value, 'quem_custeara')}
                                        />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                                    <Input
                                        className={'md:col-span-1'}
                                        large={true}
                                        type={'date'}
                                        label={'Data da entrada'}
                                        required={true}
                                        name={'data_entrada'}
                                        setValue={e => handleChange(e,'data_entrada')}
                                        value={state.data_entrada}
                                    />
                                    <Input
                                        className={'md:col-span-1'}
                                        large={true}
                                        type={'date'}
                                        label={'Data da saida'}
                                        required={true}
                                        name={'data_saida'}
                                        setValue={e => handleChange(e,'data_saida')}
                                        value={state.data_saida}
                                    />
                                    <div className={'col-span-1'}>
                                        <InputSelect
                                            label={"Quem custeará sua viagem"}
                                            selected={quemCustearaOptions.filter((opt: any) => opt.value === state.quem_custeara)}
                                            options={quemCustearaOptions}
                                            onChangeSelect={(e: any) => handleChange(e.value, 'quem_custeara')}
                                        />
                                    </div>
                                </div>
                                <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                                    {/*<Input
                                        className={'md:col-span-1'}
                                        large={true}
                                        label={'Fundos disponíveis para a sua visita (em dólares canadenses)'}
                                        required={true}
                                        name={'valor_fundo_disponivel'}
                                        setValue={e => handleChange(e,'valor_fundo_disponivel')}
                                        value={state.valor_fundo_disponivel}
                                        legenda={'O Consulado utiliza um cálculo em sua avaliação de que é recomendado que todos os visitantes tenham, pelo menos 800 dólares canadenses por mês de viagem.'}
                                    />*/}
                                    <InputCurrency
                                        moeda={'eua'}
                                        label={'Fundos disponíveis para a sua visita (em dólares canadenses)'}
                                        className={'col-span-1'}
                                        required={true}
                                        value={state.valor_fundo_disponivel}
                                        onChange={(e: any) => handleChange(e,'valor_fundo_disponivel')}
                                        decimalSeparator=","
                                        decimalScale={2}
                                        legenda={'O Consulado utiliza um cálculo em sua avaliação de que é recomendado que todos os visitantes tenham, pelo menos 800 dólares canadenses por mês de viagem.'}
                                    />
                                    <Input
                                        className={'md:col-span-1'}
                                        large={true}
                                        label={'Nome, endereço e relação com pessoa(s) ou instituição(ões) que visitarei'}
                                        required={true}
                                        name={'nome_instituicao'}
                                        setValue={e => handleChange(e,'nome_instituicao')}
                                        value={state.nome_instituicao}
                                        legenda={'Caso não tenha nenhum contato, hotel ou endereço que vá visitar, informe apenas que "Ainda não sei".'}
                                    />
                                </div>
                            </>
                    }
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero -1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}