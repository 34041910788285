import PageContainer from "../../components/container/PageContainer";
import React, {useEffect, useState} from "react";
import InputIcon from "../../components/input/InputIcon";
import InputSelect from "../../components/input/InputSelect";
import Button from "../../components/button/Button";
import {ReactComponent as AddIcon} from "../../assets/icone/add.svg";
import Table from "../../components/table/Table";
import Alert from "../../helpers/Alert";
import clientesApi from "../../services/clientesApi";
import processosApi from "../../services/processosApi";
import {QueryParams} from "../../types";
import {useNavigate} from "react-router-dom";

export default function Clientes() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [dataPaginate, setDataPaginate] = useState({
        current_page: 1,
        last_page: 2,
        total_data: 0,
        per_page: 0,
    });
    const [data, setData] = useState({});
    const [atualPageData, setAtualPageData] = useState(1);
    const [porPagina, setPorPagina] = useState(10);
    const [consulta, setConsulta] = useState('');

    const columns = [
        { label: "NOME", index: "usuario.name", type: 'string'},
        { label: "TELEFONE", index: "telefone", type: 'string'},
        { label: "ETAPA DO PROCESSO", index: "ultimo_processo", type: 'ultimo_processo' },
    ];

    async function getData(atualPage: number, porPagina?: number, busca?: string){
        //Alert.await(`Carregando dados...`);
        setLoading(true);
        const params: QueryParams = {
            page: atualPage,
            perPage: porPagina ? porPagina : 10,
            nome: busca ? busca : ''
        }
        console.log(params);
        const [clientes] = await Promise.all([clientesApi.all(params)]);
        if (!clientes.isError){
            const clientesData = clientes.data.data;
            const paginate = {
                'current_page': clientesData.current_page,
                'last_page': clientesData.last_page,
                'total_data': clientesData.total,
                'per_page': clientesData.per_page,
            };
            setDataPaginate(paginate);
            setData(clientesData.data);
        }
        setLoading(false);
        Alert.close();
    }

    useEffect(() => {
        Alert.await(`Carregando dados...`);
        getData(dataPaginate.current_page);
    }, []);

    const onPageChange = (e: number) => {
        getData(e, porPagina);
    }

    const onChangePerPage = (e: number) => {
        getData(atualPageData, e);
    }

    const onClickTable = (e: any) => {
        navigate(`/clientes/formulario/${e.id}`);
    }

    const consult = (e: any) => {
        console.log(e.length)
        setConsulta(e)

        if (e.length > 3){
            getData(1, porPagina, e);
        }
        if (e.length === 0){
            getData(1)
        }

    }

    return(
        <PageContainer pageTitle={'Clientes'}>
            <div className={'md:flex w-full md:justify-between'}>
                <div className={'grid md:grid-cols-6 gap-4 md:w-[50%]'}>
                    <InputIcon
                        classNameLabel={'md:col-span-3'}
                        name={'nome'}
                        setValue={e => consult(e.target.value)}
                        value={consulta}
                        placeholder={'Pesquisar'}
                    />
                </div>
                <div>
                    <Button className={'flex bg-[#082a60] text-white w-[146px] h-[48px]'} onClick={() => navigate('/clientes/formulario')}>
                        <AddIcon className="fill-white mr-1 " /> Cadastrar
                    </Button>
                </div>
            </div>
            {
                loading ? '' :
                    <div className={'w-full'}>
                        <Table
                            columns={columns}
                            tableData={data}
                            data={dataPaginate}
                            onPageChange={onPageChange}
                            setPerPage={onChangePerPage}
                            onClick={onClickTable}
                        />
                    </div>
            }
        </PageContainer>
    )
}