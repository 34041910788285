import {ITableHeader} from "../../../types";

export default function Table ({
   columns
}: ITableHeader) {
    return(
        <thead className={'text-left h-[48px] bg-[#fcfcfd] border border-slate-300 '}>
            <tr>
                {
                    columns.map(({label, index, w, type}) => {
                        return (
                            <th key={index} className={`${w ? `w-[${w}]` : ''}`}>
                                <span className={'flex'}>{type === 'button' ? '' : label.toUpperCase()}</span>
                            </th>
                        )
                    })
                }
            </tr>
        </thead>
    )
}