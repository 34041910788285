import React, {useEffect, useState} from "react";
import conjugeApi from "../../../../../services/conjugeApi";
import clientesApi from "../../../../../services/clientesApi";
import ViaCepService from "../../../../../services/viaCepApi";
import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Alert from "../../../../../helpers/Alert";
import passaporteApi from "../../../../../services/passaporteApi";
import processosApi from "../../../../../services/processosApi";
import Button from "../../../../../components/button/Button";
import Input from "../../../../../components/input/Input";
import InputSelect from "../../../../../components/input/InputSelect";

export interface IDadosContatoCa{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DadosContatoCa({processo, setProcesso, setTelaNumero, telanumero}: IDadosContatoCa) {
    const [dadosPessoais, setDadosPessoais] = useState({
        id: '',
        cep: '',
        endereco: '',
        bairro: '',
        cidade: '',
        pais_natal: '',
        tipo_telefone: 'Celular',
        telefone: '',
        tipo_telefone_secundario: 'Celular',
        telefone_secundario: '',
        email: '',
    })
    const tipoTelefoneOptions = [
        { value: 'Residencial', label: 'Residencial' },
        { value: 'Trabalho', label: 'Trabalho' },
        { value: 'Celular', label: 'Celular' },
    ];
    const getData = async () => {
        const {data, isError} = await clientesApi.one(processo.cliente_id);
        if (!isError){
            if (Object.values(data.data).length){
                const response = data.data;
                setDadosPessoais({
                    id: response.id,
                    cep: response.cep,
                    endereco: response.endereco,
                    bairro: response.bairro,
                    cidade: response.cidade,
                    pais_natal: response.pais_natal,
                    tipo_telefone: response.tipo_telefone,
                    telefone: response.telefone,
                    tipo_telefone_secundario: response.tipo_telefone_secundario,
                    telefone_secundario: response.telefone_secundario,
                    email: response.usuario.email,
                })
            }
        }
    }
    useEffect(() => {
        getData()
    }, []);
    const handleChange = (value: any, input: string) => {
        setDadosPessoais({...dadosPessoais, [input]: value});
    };
    const cepSrv = new ViaCepService();
    const handleChangeCep = (value: any) => {
        cepSrv.consultaCep(dadosPessoais.cep).then((cepResponse: any) =>{
            if (!cepResponse.data.erro){
                setDadosPessoais({
                    ...dadosPessoais,
                    endereco: cepResponse.data.logradouro,
                    bairro: cepResponse.data.bairro,
                    cidade: cepResponse.data.localidade+' '+cepResponse.data.uf,
                    pais_natal: 'Brasil',

                });
            }
        });
    }
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = await clientesApi.update(dadosPessoais.id, dadosPessoais);
        if (isError){
            console.log(data)
            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (processo.percet_conclusao === 26.67){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }

    return(
        <>
            <HeaderForm
                titulo={'Informações de contato'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            large={true}
                            className={'md:col-span-1'}
                            label={'CEP'}
                            name={'cep'}
                            mask={"CEP"}
                            setValue={e => handleChange(e, 'cep')}
                            onBlur={(e: any) => handleChangeCep(e)}
                            value={dadosPessoais.cep}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Endereço'}
                            name={'endereco'}
                            setValue={e => handleChange(e,'endereco')}
                            value={dadosPessoais.endereco}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Bairro'}
                            name={'bairro'}
                            setValue={e => handleChange(e,'bairro')}
                            value={dadosPessoais.bairro}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            required={true}
                            large={true}
                            className={'md:col-span-1'}
                            label={'Cidade e estado'}
                            name={'cidade'}
                            setValue={e => handleChange(e,'cidade')}
                            value={dadosPessoais.cidade}
                        />
                        <Input
                            className={'md:col-span-1'}
                            required={true}
                            large={true}
                            label={'País'}
                            name={'pais_natal'}
                            setValue={e => handleChange(e,'pais_natal')}
                            value={dadosPessoais.pais_natal}
                        />
                        <Input
                            inputType={'email'}
                            className={'col-span-1'}
                            label={'E-mail '}
                            placeholder={'E-mail'}
                            large={true}
                            name={'email'}
                            setValue={e => handleChange(e,'email')}
                            value={dadosPessoais.email}
                        />
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Tipo de telefone"}
                                selected={tipoTelefoneOptions.filter((opt: any) => opt.value === dadosPessoais.tipo_telefone)}
                                options={tipoTelefoneOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'tipo_telefone')}
                            />
                        </div>
                        <Input
                            mask={'cellphone'}
                            className={'col-span-1'}
                            label={'Telefone'}
                            placeholder={'Telefone'}
                            large={true}
                            required={true}
                            name={'telefone'}
                            setValue={e => handleChange(e,'telefone')}
                            value={dadosPessoais.telefone}
                        />
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Tipo de telefone secundario"}
                                selected={tipoTelefoneOptions.filter((opt: any) => opt.value === dadosPessoais.tipo_telefone_secundario)}
                                options={tipoTelefoneOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'tipo_telefone_secundario')}
                            />
                        </div>
                        <Input
                            mask={'cellphone'}
                            className={'col-span-1'}
                            label={'Telefone secundário'}
                            placeholder={'Telefone secundário'}
                            large={true}
                            name={'telefone_secundario'}
                            setValue={e => handleChange(e,'telefone_secundario')}
                            value={dadosPessoais.telefone_secundario}
                        />
                    </div>
                </div>

                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero -1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}