import TableHeader from "../TableHeader";
import TableBody from "../TableBody";
import {ITable} from "../../../types";
import {PaginationNav1Presentation} from "../Pagination";

export default function Table ({
    columns,
    tableData,
    data,
    onPageChange,
    setPerPage,
    onClick,
    page,
    perPage,
}: ITable) {
    return(
        <>
            <table className="w-full border-collapse border border-slate-40">
                <TableHeader columns={columns}/>
                <TableBody columns={columns} tableData={tableData} onClick={onClick}/>
            </table>
            {
                data ?
                    <div className={'w-full flex items-center h-[80px] bg-[#fcfcfd] border border-slate-300 px-3 justify-between'}>
                        <div className={'text-[#424242]'}>
                            Exibido por página {
                            <select onChange={e => setPerPage(e.target.value)} defaultValue={perPage} className={'border-2 p-1 rounded font-bold'}>
                                <option value={10} >10</option>
                                <option value={25} >25</option>
                                <option value={50}>50</option>
                            </select>
                        } de <span className={'font-bold'}>{data.total_data ?? data?.total}</span> resultados
                        </div>


                        <div>
                            {
                                onPageChange ?
                                    !!tableData && <PaginationNav1Presentation
                                        page={page ??data?.current_page}
                                        onPageChange={onPageChange}
                                        totalPaginas={data.totalPage ?? data?.last_page}
                                        lastPage={data.last_page}
                                    />
                                    :
                                    ''
                            }

                        </div>
                    </div>
                    :
                    ''

            }

        </>


    )
}